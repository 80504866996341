<!--
 * File: catalog-wizard.component.html                                         *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 18th July 2022 15:03:29                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->

<mat-stepper [linear]="true" #stepper >
  <ng-template matStepperIcon="edit">
    <mat-icon>done</mat-icon>
  </ng-template>
  <mat-step [stepControl]="catalogInfoStep" >
    <ng-template matStepLabel>{{'CATALOG.CATALOG_DATA' | translate}}</ng-template>
    <form [formGroup]="catalogInfoStep">
      <input type="hidden" formControlName="isValid" />
    </form>
    <div fxFill fxLayout="column" fxLayoutAlign="space-between center">
      <app-catalog-form
        fxFill
        [id]="id"
        (frmToParent)="changeMainForm($event)"
      ></app-catalog-form>
    </div>
    <div fxFill fxLayoutAlign="space-between center">
      <span></span>
      <button
        mat-mini-fab
        (click)="checkCatalogForm()"
        color="primary"
        [disabled]="catalogForm.valid ? false : true"
      >
        <mat-icon>arrow_right</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step
    [stepControl]="checkDifferencesStep"
    label="{{'CATALOG.SYNC' | translate}}"
  >
    <form [formGroup]="checkDifferencesStep">
      <input type="hidden" formControlName="isValid" />
    </form>

    <app-catalog-sync-differences
      [idCatalog]="idCatalog"
      (preSyncEmmiter)="isPreSync($event)"
    ></app-catalog-sync-differences>

    <div fxFill fxLayoutAlign="space-between center">
      <button mat-mini-fab matStepperPrevious color="primary">
        <mat-icon>arrow_left</mat-icon>
      </button>
      <button
        mat-mini-fab
        [disabled]="!preSyncIsValid"
        (click)="setCloseForm()"
        color="primary"
      >
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </mat-step>
<!--
  <mat-step [stepControl]="syncStep" [label]="syncStepLabel">
    <form [formGroup]="syncStep">
      <input type="hidden" formControlName="isValid" />
    </form>
    <app-catalog-sync
      [idCatalog]="idCatalog"
      (syncFinish)="setSyncFinish()"
    ></app-catalog-sync>
    <div fxFill fxLayoutAlign="space-between center">
      <button mat-mini-fab matStepperPrevious color="primary">
        <mat-icon>arrow_left</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="primary"
        [disabled]="!syncIsValid"
        (click)="setCloseForm()"
      >
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </mat-step> -->
</mat-stepper>
