<!--
File: catalog-form.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 16:54:43
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<ng-container *ngIf="loadedForm">
  <form [formGroup]="form" *ngIf="form" class="cc-mat-form" autocomplete="off">
    <div formGroupName="mainFields" class="base-form-grid">
      <app-form-generator
        *ngFor="let field of orderedMainFields"
        [field]="field"
        [formGroup]="fgMain"
      ></app-form-generator>
    </div>
    <div class="test-connection" *ngIf="testConnectionShow">
      <button
        mat-raised-button
        [disabled]="chekingConnection"
        (click)="testConnection()"
        class="button-test-connection"
      >
        {{ "CATALOG.TEST_CONNECTION" | translate }}
        <mat-progress-bar  mode="buffer" *ngIf="chekingConnection"></mat-progress-bar>
      </button>
      <div class="test-connection-info">
        <span >

        </span>
        <mat-icon *ngIf="testConnectionMessage.length > 0">{{
          testConnectionIcon
        }}</mat-icon>
        <span *ngIf="testConnectionMessage.length > 0">
          {{ testConnectionMessage }}</span
        >
      </div>
    </div>
    <mat-divider></mat-divider>
    <div>
      <h3 style="text-align: center; font-weight: normal">
        {{ "CATALOG.MORE_INFO" | translate }}
      </h3>
    </div>
    <div fxFill fxLayout="row">
      <div fxFill fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
        <mat-form-field appearance="outline" style="width: 50%">
          <mat-label> {{ "CATALOG.ADD_NEW_OPT_FIELD" | translate }} </mat-label>
          <input
            matInput
            type="text"
            name="addOptionaField"
            id="addOptionaField"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
            #addOptionalField
            (keyup.enter)="addField(addOptionalField)"
          />
          <button
            mat-icon-button
            matSuffix
            [style]="
              addOptionalField.value.length > 0
                ? 'display:block'
                : 'display:none'
            "
            (click)="addField(addOptionalField)"
          >
            <mat-icon>done</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="base-form-grid" formGroupName="extraFields">
      <mat-form-field
        appearance="outline"
        class="full-width"
        *ngFor="let control of extraFields"
      >
        <mat-label>{{ control.label }}</mat-label>
        <input
          matInput
          formControlName="{{ control.name }}"
          value="{{ control.value }}"
        />
      </mat-form-field>
    </div>
  </form>
</ng-container>
