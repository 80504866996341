<!--
 * File: page-template-list.component.html                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 1st June 2022 13:49:45                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd June 2022 10:17:47                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->

<cc-top-menu context="pageTemplate"></cc-top-menu>
<div style="width: 50%; margin: 2em auto" >
  <table
    mat-table
    [dataSource]="dataTable"
    class="mat-elevation-z3 table-page-template"
    matSort
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">{{ "PAGE.NAME" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="columns">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header>
        {{ "PAGE.COLUMNS" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.columns }}</td>
    </ng-container>
    <ng-container matColumnDef="rows">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "PAGE.ROWS" | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.rows }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ "PAGE.ACTIONS" | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="{{ 'PAGE.EDIT' | translate }}" (click)="editTemplate(element.id)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{ 'PAGE.REMOVE' | translate }}" (click)="removeTemplate(element.id)">
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button mat-icon-button matTooltip="{{ 'PAGE.CLONE' | translate }}" (click)="cloneTemplate(element.id)">
          <mat-icon>file_copy</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
