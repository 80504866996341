<!--
 * File: cc-cell-item-variant-selector.component.html                          *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 25th May 2022 13:51:32                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 27th May 2022 10:06:50                               *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->

<button
  *ngIf="variants.length > 0"
  mat-icon-button
  [matMenuTriggerFor]="productVariant"
  aria-label="Model type product"
>
  <mat-icon>app_registration</mat-icon>
</button>

<mat-menu #productVariant>
  <mat-selection-list #shoes [multiple]="false">
    <mat-list-option
      *ngFor="let item of variants"
      (click)="changeVariant(item)"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <img
          src="{{ item.image }}"
          style="width: 32px; margin-right: 1em"
        />
        <span>{{ item.name }}</span>
      </div>
    </mat-list-option>
  </mat-selection-list>
</mat-menu>
