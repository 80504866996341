<!--
File: catalog-upload.component.html
Project: catalog-cloud
File Created: Wednesday, 16th February 2022 13:06:31
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:15:03
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div *ngIf="checkData">
  <h3 style="text-align: center; font-weight: normal;">{{ 'REVIEW_DATA_UPLOAD' | translate }}:</h3>
  <div class="header-checkData">
    <span *ngIf="checkData.new > 0">{{ 'NEWS' | translate }}: {{checkData.new}}</span>
    <span *ngIf="checkData.update > 0">{{ 'UPDATES' | translate }}: {{checkData.update}}</span>
    <span *ngIf="checkData.delete > 0">{{ 'DELETES' | translate }}: {{checkData.delete}}</span>
  </div>

</div>

<h3 *ngIf="uploaded"><mat-icon>check</mat-icon>{{ 'UPLOAD_SUCCESSFUL' | translate }} </h3>
<div fxLayout="column" fxLayoutAlign="center center" fxFill class="syncro" *ngIf="!uploaded">
  <button mat-raised-button color="primary" (click)="upload()" [disabled]="loading">
    {{ 'UPLOAD' | translate }}
  </button>
</div>

<div fxLayout="column" fxLayoutAlign="center center" fxFill>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>


