/*
 * File: menu.component.ts                                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 16th March 2022 13:16:55                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnInit } from '@angular/core';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-user-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {


  @Input() user: IUser
  constructor(private userService:UserService,private router:Router){}

  ngOnInit(): void {
  }

  logout(){
    this.userService.logout();
    this.router.navigate(['login'])
  }

  editMe(){
    this.userService.editForm();
  }


}
