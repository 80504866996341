/*
 * File: catalog-cloud.module.ts                                               *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 10:59:39                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSnackBarModule } from '@angular/material/snack-bar';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../core/interceptors/auth-interceptor';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { HttpErrorInterceptor } from '../core/interceptors/http-error.interceptor';
import { HttpNoCacheInterceptor } from '../core/interceptors/http-no-cache.interceptor';

import { CoreModule } from '../core/core.module';

import { MenuComponent } from './components/user/menu/menu.component';
import { TopMenuComponent } from './components/menus/top-menu/top-menu.component';
import { LoginComponent } from './components/user/login/login.component';
import { ResetPasswordComponent } from './components/user/reset-password/reset-password.component';

import { CatalogFormComponent } from './components/catalog/catalog-form/catalog-form.component';
import { CatalogListComponent } from './components/catalog/catalog-list/catalog-list.component';
import { CatalogActionMenuComponent } from './components/catalog/catalog-action-menu/catalog-action-menu.component';

import { CookieService } from 'ngx-cookie-service';

import { ActionsMenuService} from './services/menus/actionsMenu.service';

import { CatalogService } from './services/catalog/catalog.service';
import { FascicleService } from './services/fascicle/fascicle.service';

import { UserService } from './services/user/user.service';

import { CatalogHomeComponent } from './components/catalog/catalog-home/catalog-home.component';
import { CatalogDeleteComponent } from './components/catalog/catalog-delete/catalog-delete.component';
import { CatalogSyncComponent } from './components/catalog/catalog-sync/catalog-sync.component';

import { FascicleFormComponent } from './components/fascicle/fascicle-form/fascicle-form.component';
import { FascicleEditComponent } from './components/fascicle/fascicle-edit/fascicle-edit.component';
import { FascicleListComponent } from './components/fascicle/fascicle-list/fascicle-list.component';

import { CcGridComponent } from './components/grid/cc-grid/cc-grid.component';
import { CcGridSelectorComponent } from './components/grid/cc-grid-selector/cc-grid-selector.component';
import { CcCellComponent } from './components/grid/cc-cell/cc-cell.component';
import { CcCellItemComponent } from './components/grid/cc-cell-item/cc-cell-item.component';
import { CcCellButtonComponent } from './components/grid/cc-cell-button/cc-cell-button.component';

import { BreadcrumbsComponent } from './components/menus/breadcumbs/breadcrumbs.component';

import { FasciclePaginatorComponent } from './components/fascicle/fascicle-paginator/fascicle-paginator.component';

import { ProductToolbarComponent } from './components/product/product-toolbar/product-toolbar.component';
import { ProductGridComponent } from './components/product/product-grid/product-grid.component';
import { ProductGridItemComponent } from './components/product/product-grid-item/product-grid-item.component';
import { ProductListItemComponent } from './components/product/product-list-item/product-list-item.component';
import { ProductListComponent } from './components/product/product-list/product-list.component';
import { ProductFormComponent } from './components/product/product-form/product-form.component';
import { CatalogWizardComponent } from './components/catalog/catalog-wizard/catalog-wizard.component';
import { CatalogSyncDifferencesComponent } from './components/catalog/catalog-sync-differences/catalog-sync-differences.component';
import { CatalogWizardSyncComponent } from './components/catalog/catalog-wizard-sync/catalog-wizard-sync.component';
import { ProductDetailsComponent } from './components/product/product-details/product-details.component';
import { FascicleSocketService } from './services/fascicle/fascicle-socket.service';
import { FascicleAskUnblockComponent } from './components/fascicle/fascicle-ask-unblock/fascicle-ask-unblock.component';
import { CatalogUploadComponent } from './components/catalog/catalog-upload/catalog-upload.component';
import { CcCellItemModelSelectorComponent } from './components/grid/cc-cell-item-model-selector/cc-cell-item-model-selector.component';
import { ListComponent } from './components/user/list/list.component';
import { CreateComponent } from './components/user/create/create.component';
import { UpdateComponent } from './components/user/update/update.component';
import { ProductToolbarSearchComponent } from './components/product/product-toolbar-search/product-toolbar-search.component';
import { ProductToolbarSearchInfoComponent } from './components/product/product-toolbar-search-info/product-toolbar-search-info.component';
import { ProductToolbarProductDetailsComponent } from './components/product/product-toolbar-product-details/product-toolbar-product-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { EditComponent } from './components/user/edit/edit.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { FascicleDeleteComponent } from './components/fascicle/fascicle-delete/fascicle-delete.component';
import { PageDeleteComponent } from './components/page/page-delete/page-delete.component';
import { CcCellItemVariantSelectorComponent } from './components/grid/cc-cell-item-variant-selector/cc-cell-item-variant-selector.component';
import { CcCellItemExtraFieldsFormComponent } from './components/grid/cc-cell-item-extra-fields-form/cc-cell-item-extra-fields-form.component';
import { PageTemplateListComponent } from './components/page/page-template-list/page-template-list.component';
import { PageTemplateFormComponent } from './components/page/page-template-form/page-template-form.component';
import { FascicleListAloneComponent } from './components/fascicle/fascicle-list-alone/fascicle-list-alone.component';
import { BackupsListComponent } from './components/backups/backups-list/backups-list.component';
import { ActionsMenuComponent } from './components/menus/actions-menu/actions-menu.component';
import { DropboxComponent } from './components/backups/dropbox/dropbox.component';

@NgModule({
  declarations: [
    MenuComponent,
    TopMenuComponent,
    ActionsMenuComponent,

    BreadcrumbsComponent,

    LoginComponent,
    ResetPasswordComponent,

    CatalogFormComponent,
    CatalogListComponent,
    CatalogActionMenuComponent,
    CatalogDeleteComponent,
    CatalogHomeComponent,
    CatalogSyncComponent,

    FascicleListComponent,
    FascicleFormComponent,
    FasciclePaginatorComponent,
    FascicleEditComponent,

    CcGridComponent,
    CcGridSelectorComponent,
    CcCellComponent,
    CcCellItemComponent,
    CcCellButtonComponent,

    ProductToolbarComponent,
    ProductGridComponent,
    ProductGridItemComponent,
    ProductListItemComponent,
    ProductListComponent,
    ProductFormComponent,
    CatalogWizardComponent,
    CatalogSyncDifferencesComponent,
    CatalogWizardSyncComponent,
    ProductDetailsComponent,
    FascicleAskUnblockComponent,
    CatalogUploadComponent,
    CcCellItemModelSelectorComponent,
    ListComponent,
    CreateComponent,
    UpdateComponent,
    ProductToolbarSearchComponent,
    ProductToolbarSearchInfoComponent,
    ProductToolbarProductDetailsComponent,
    EditComponent,
    FascicleDeleteComponent,
    PageDeleteComponent,
    CcCellItemVariantSelectorComponent,
    CcCellItemExtraFieldsFormComponent,
    PageTemplateListComponent,
    PageTemplateFormComponent,
    FascicleListAloneComponent,
    BackupsListComponent,
    DropboxComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatSelectModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatListModule,
    MatChipsModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    DragDropModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatStepperModule,
    MatRadioModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    TranslateModule,
    InfiniteScrollModule
  ],
  exports: [
    TopMenuComponent,
    LoginComponent,
    CatalogFormComponent,
    CatalogListComponent,
    ActionsMenuComponent,
    ResetPasswordComponent,

  ],
  providers: [
    CookieService,
    ActionsMenuService,
    CatalogService,
    FascicleService,
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpNoCacheInterceptor,
      multi: true,
    },
    FascicleSocketService
  ],
})
export class CatalogCloudModule {}
