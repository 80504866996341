/*
 * File: page-template-list.component.ts                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 1st June 2022 13:49:45                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd June 2022 10:17:57                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { PageTemplate } from 'src/app/catalog-cloud/interfaces/fascicle/page-template.interface';
import { PageTemplateService } from 'src/app/catalog-cloud/services/page/page-template.service';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-page-template-list',
  templateUrl: './page-template-list.component.html',
  styleUrls: ['./page-template-list.component.scss'],
})
export class PageTemplateListComponent implements OnInit {
  dataTable: MatTableDataSource<PageTemplate>;
  loading: boolean = true;
  displayedColumns = ['name', 'columns', 'rows', 'actions'];

  checkModifiedTempaltes: Subscription;
  user: IUser;
  constructor(
    private pageTemplateService: PageTemplateService,
    private userService: UserService
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.user = this.userService.getUser();

    this.subscriptions();
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.pageTemplateService.getTemplates().subscribe((data) => {
      this.dataTable = new MatTableDataSource<PageTemplate>(data);
      // this.dataTable.data = data;
      this.dataTable.sort = this.sort;

      this.loading = false;
    });
  }

  editTemplate(id: string) {
    this.pageTemplateService.editPageTemplateForm(this.user.id, id);
  }

  cloneTemplate(id:string)
  {
    this.pageTemplateService.cloneTemplate(this.user.id,id);
  }

  removeTemplate(id: string) {
    this.pageTemplateService.removeTemplate(id);
  }

  subscriptions() {
    this.checkModifiedTempaltes =
      this.pageTemplateService.templatesModified.subscribe((data) => {
        this.loadData();
      });
  }

}
