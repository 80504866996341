/*
 * File: fascicle-list-alone.component.ts                                      *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 3rd June 2022 11:54:57                                *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 3rd June 2022 12:28:19                               *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Route } from '@angular/router';
import { Subscription } from 'rxjs';
import { Fascicle } from 'src/app/catalog-cloud/interfaces/fascicle/fascicle.interface';
import { CatalogService } from 'src/app/catalog-cloud/services/catalog/catalog.service';
import { FascicleSocketService } from 'src/app/catalog-cloud/services/fascicle/fascicle-socket.service';
import { FascicleService } from 'src/app/catalog-cloud/services/fascicle/fascicle.service';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { PopInfoService } from 'src/app/core/services/pop-info-service.service';

@Component({
  selector: 'app-fascicle-list-alone',
  templateUrl: './fascicle-list-alone.component.html',
  styleUrls: ['./fascicle-list-alone.component.scss']
})
export class FascicleListAloneComponent implements OnInit {

  dataSuscribe: any;
  dataTableSuscribe: any;
  loadingSuscribe: any;
  socketSuscribe: Subscription;

  idCatalog: string;

  dataLoaded: boolean = false;
  loading: boolean = false;

  dataSource: MatTableDataSource<Fascicle>;
  dataTable: Array<Fascicle>;

  row: any;
  pageFrom = 0;
  viewActions: boolean = false;
  user: IUser;

  @ViewChild('fasTable') table: MatTable<Fascicle>;

  // @Input() set dataInput(value: Array<Fascicle>) {
  //   this.dataTable = this.fascicleService.setFromToPages(value);
  //   this.dataSource = new MatTableDataSource(this.dataTable);
  //   this.dataLoaded = true;
  // }

  //Table columns displayed (and order)
  displayedColumns: string[] = [
    'moveRow',
    'name',
    'author',
    'updatedAt',
    'pages',
    'actions',
  ];

  constructor(
    private fascicleService: FascicleService,
    private catalogService: CatalogService,
    private popInfoService: PopInfoService,
    private userService: UserService,
    private socket: FascicleSocketService,
    private route:ActivatedRoute
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    //this.dataLoaded = true;
    this.idCatalog = this.route.snapshot.params.idCatalog;

    this.user = this.userService.getUser();
    this.socketSuscribe = this.socket.fasciclesChanged.subscribe((change) => {
      this.reload();
    });

    this.dataSuscribe = this.fascicleService.dataTableFascicles.subscribe(
      (data) => {
        this.dataSource = data;
      }
    );

    this.dataTableSuscribe = this.fascicleService.dataFascicles.subscribe(
      (data) => {
        this.dataTable = data;
      }
    );

    this.loadingSuscribe = this.fascicleService.loadingFascicle.subscribe(
      (load) => {
        this.loading = load;
      }
    );
    this.loadFascicles();
  }

  loadFascicles(){
    this.fascicleService.fetch(this.idCatalog).subscribe((data)=>{
      this.dataTable = this.fascicleService.setFromToPages(data);
      this.dataSource = new MatTableDataSource(this.dataTable);
      this.dataLoaded = true;
    })
  }

  ngOnDestroy(): void {
    this.dataSuscribe.unsubscribe();
    this.dataTableSuscribe.unsubscribe();
    this.loadingSuscribe.unsubscribe();
    this.socketSuscribe.unsubscribe();
    // this.socket.fasciclesChanged.unsubscribe();
  }

  handleMouseOver(row: any) {
    if (!this.loading) {
      row.isHover = true;
      if (document.getElementById('actions-row-' + row.id))
        document
          .getElementById('actions-row-' + row.id)
          .classList.remove('actions-not-visible');
      if (document.getElementById('move-' + row.id))
        document
          .getElementById('move-' + row.id)
          .classList.remove('move-fascicle-not-visible');
    }
  }

  handleMouseLeave(row: any) {
    if (!this.loading) {
      row.isHover = false;
      if (document.getElementById('actions-row-' + row.id))
        document
          .getElementById('actions-row-' + row.id)
          .classList.add('actions-not-visible');
      if (document.getElementById('move-' + row.id))
        document
          .getElementById('move-' + row.id)
          .classList.add('move-fascicle-not-visible');
    }
  }

  changeFascicleOrder(event: CdkDragDrop<any>) {
    this.loading = true;
    const prevIndex = this.dataSource.data.findIndex(
      (d) => d === event.item.data
    );
    moveItemInArray(this.dataSource.data, prevIndex, event.currentIndex);

    // this.dataSource.data = this.fascicleService.setFromToPages(
    //   this.dataSource.data
    // );

    this.table.renderRows();
    console.debug('fascicle-list', this.dataSource.data);
    this.fascicleService.updateFascicleOrder(
      this.idCatalog,
      this.dataSource.data
    );
    this.fascicleService.setFromToPages(this.dataSource.data);
    this.loading = false;
  }

  openFascicleSettings(id: string) {
    this.fascicleService.editFascicleProperties(id, this.idCatalog);
  }

  editFascicle(id: string) {
    this.fascicleService.openForm(id, this.idCatalog);
  }

  reload() {
    this.catalogService.getCatalog(this.idCatalog).subscribe((data) => {
      this.dataTable = this.fascicleService.setFromToPages(data.fascicles);
      this.dataSource = new MatTableDataSource(this.dataTable);
    });
  }

  clone(id: string) {
    this.fascicleService.clone(this.idCatalog, id).subscribe((data) => {
      this.dataLoaded = true;
      this.reload();
      this.popInfoService.addMessage(
        'info',
        'Fascículo clonado',
        'Clonación de fasciculo'
      );
    });
  }

  async delete(id: string, catalog: string) {
    this.fascicleService.deleteModal(catalog,id).subscribe((remove) => {
      console.log(remove)
      if (remove) {
        this.fascicleService.delete(catalog,id).subscribe((deleted) => {
          console.log(deleted)
          this.reload();
          this.popInfoService.addMessage('info','Fascículo Eliminado');
        });

      }
    });
  }

  showFromTo(fascicle: Fascicle) {
    let from = this.pageFrom + 1;
    let to = fascicle.totalPages + this.pageFrom;

    this.pageFrom = to;
    return from + ' - ' + to;
  }

  requestUnBlock(fascicle: Fascicle) {
    this.fascicleService.get(this.idCatalog, fascicle.id).subscribe((fasc) => {
      console.log(fasc);
      this.socket.sendUnblockRequestFascicle(
        this.idCatalog,
        fascicle.id,
        this.userService.getUser().id,
        fasc.blockedBy
      );
    });
  }

}
