<!--
File: reset-password.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 19:07:55
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<ng-template [ngIf]="(resetForm | async) === true">
  <div fxLayout="column" fxLayoutAlign="center center" class="login-background">
    <h1><img src="./assets/img/nousmedis_logo.svg" /></h1>
    <mat-card class="login-container mat-elevation-z3">
      <mat-card-title
        >{{ "USER.RESTORE_PASSWORD" | translate }}
      </mat-card-title>

      <mat-card-content>
        <div fxLayoutGap="20px grid">
          <div>
            <form *ngIf="!isSended && !isLoading">
              <div fxFill>
                <span>{{ "USER.RECOVER_MAIL" | translate }} </span>
              </div>
              <div fxFill>
                <mat-form-field appearance="outline" class="full-row">
                  <mat-label>{{ 'USER.USER' | translate }} </mat-label>
                  <input
                    matInput
                    type="email"
                    matInput
                    #loginInput
                    (ngModel)="(loginInput)"
                    [formControl]="emailFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="Ex. cc@nousmedis.com"
                  />
                  <mat-error
                    *ngIf="
                      emailFormControl.hasError('email') &&
                      !emailFormControl.hasError('required')
                    "
                  >
                    {{ "USER.INVALID_MAIL" | translate }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxFill>
                <button
                  mat-flat-button
                  color="primary"
                  (click)="tryReset()"
                  class="full-row disabled"

                >
                  {{ 'USER.RESTORE_PASSWORD' | translate }}
                </button>
              </div>
            </form>
            <span *ngIf="isLoading">
              <mat-spinner></mat-spinner>
            </span>
            <div *ngIf="isSended" fxFill>
              <span style="text-align: justify;display: block;"
                >{{ 'USER.SEND_SUCCESSFUL' | translate }} </span
              >

              <button mat-raised-button color="primary" [routerLink]="'/'">
                {{ "GO_TO_LOGIN" | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>

<ng-template [ngIf]="(hasParameters | async) === true">
  <div fxLayout="column" fxLayoutAlign="center center" class="login-background">
    <h1><img src="./assets/img/nousmedis_logo.svg" /></h1>
    <mat-card class="login-container mat-elevation-z3">
      <mat-card-title>
        {{ "USER.RESTORE_PASSWORD" | translate }}
      </mat-card-title>

      <mat-card-content *ngIf="goodToken">
        <div fxLayoutGap="20px grid">
          <div>
            <form
              *ngIf="!isSended && !isLoading"
              [formGroup]="frmSetPass"
              (ngSubmit)="sendNewPass()"
            >
              <div fxFill>
                <span
                  >{{ 'USER.SET_NEW_PASSWORD' | translate }} </span
                >
              </div>
              <div fxFill>
                <mat-form-field appearance="outline" class="full-row">
                  <mat-label>{{ 'USER.NEW_PASSWORD' | translate }} </mat-label>
                  <input
                    matInput
                    type="password"
                    formControlName="newPassword"
                    placeholder="Password"
                    required
                  />
                  <mat-error
                    *ngIf="
                      fp.newPassword.errors && fp.newPassword.errors.pattern
                    "
                  >
                    {{ 'USER.PASS_STRENGHT_INVALID' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFill>
                <mat-form-field appearance="outline" class="full-row">
                  <mat-label>{{ 'USER.REPEAT_PASSWORD' | translate }} </mat-label>
                  <input
                    type="password"
                    matInput
                    formControlName="confirmPassword"
                    placeholder="{{ 'USER.REPEAT_PASSWORD' | translate }}"
                  />
                  <mat-error *ngIf="fp.errors"> {{ 'USER.PASS_STRENGHT_INVALID' | translate }} </mat-error>
                </mat-form-field>
              </div>

              <div fxFill>
                <button
                  mat-flat-button
                  color="primary"
                  type="submit"
                  class="full-row"
                  [disabled]="!frmSetPass.valid"
                >
                  {{ 'USER.RESTORE_PASSWORD' | translate }}
                </button>
              </div>
            </form>
            <span *ngIf="isLoading">
              <mat-spinner></mat-spinner>
            </span>
            <span *ngIf="isSended">
              {{ sendedMessage }}
            </span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="!goodToken">
        <span>{{ 'USER.TOKEN_EXPIRED' | translate }} </span>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
