<!--
File: fascicle-form.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:29:07
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<app-cg-loader *ngIf="!loadedForm"></app-cg-loader>

<ng-container *ngIf="loadedForm">
  <form
    [formGroup]="form"
    *ngIf="form"
    autocomplete="off"
    class="base-fascicle-form"
  >
    <div formGroupName="mainFields" class="full-width">
      <app-form-generator
        *ngFor="let field of orderedMainFields"
        [field]="field"
        [formGroup]="fgMain"
      ></app-form-generator>
    </div>

    <mat-divider></mat-divider>

    <div formGroupName="categoryFields" class="full-width">
      <app-form-generator
        [field]="categoriesField[0]"
        [dataAutoComplete]="dataAutoComplete"
        [formGroup]="fgCategories"
        (returnSelected)="addItem($event)"
        (returnRemoved)="removeItem($event)"
        [formGroup]="fgCategories"
      ></app-form-generator>

    </div>
    <div formGroupName="tagFields" class="full-width">
      <app-form-generator
        [field]="tagsField[0]"
        [dataAutoComplete]="dataAutoComplete"
        [formGroup]="fgTags"
        (returnSelected)="addItem($event)"
        (returnRemoved)="removeItem($event)"
        [formGroup]="fgTags"
      ></app-form-generator>
    </div>
  </form>

  <app-cg-loader *ngIf="searchingProducts"></app-cg-loader>
  <span *ngIf="!searchingProducts"
    > {{ 'FASCICLE.TOTAL_PRODUCT_FOUND' | translate }}  {{ totalProducts }}</span
  >
</ng-container>


