/*
 * File: page-template.service.ts                                              *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd June 2022 10:18:20                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ModalDialogComponent } from 'src/app/core/components/modal-dialog/modal-dialog.component';
import { PageTemplateFormComponent } from '../../components/page/page-template-form/page-template-form.component';
import { PageTemplate } from '../../interfaces/fascicle/page-template.interface';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class PageTemplateService {
  url = '/page-templates';
  templatesModified: Subject<boolean> = new Subject<boolean>();
  constructor(private api: ApiService, private modal: MatDialog) {}

  getTemplates() {
    return this.api.get(this.url + '/');
  }

  getTemplate(id: string) {
    return this.api.get(this.url + '/' + id);
  }

  cloneTemplate(userId: string, id: string) {
    return this.api
      .post(this.url + '/clone', { id: id, idUser: userId })
      .subscribe((data) => {
        this.templatesModified.next(true);
      });
  }

  removeTemplate(id: string) {
    return this.api.delete(this.url + '/' + id).subscribe((data) => {
      this.templatesModified.next(true);
    });
  }

  setTemplate(pageTemplate: PageTemplate) {
    return this.api.post(this.url + '/', pageTemplate);
  }

  updateTemplate(pageTemplate: PageTemplate) {
    return this.api.patch(this.url + '/', pageTemplate);
  }

  editPageTemplateForm(userId: string, idTemplate: string) {
    return this.modal
      .open(ModalDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: 'mat-elevation-z8',
        data: {
          title: 'Editar plantilla de página',
          component: PageTemplateFormComponent,
          idTemplate: idTemplate,
          idUSer: userId,
          type: 'form',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.updateTemplate(data['mainFields']).subscribe((data) => {
          this.templatesModified.next(true);
        });
      });
  }

  newPageTemplateForm(userId: string) {
    return this.modal
      .open(ModalDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: 'mat-elevation-z8',
        data: {
          title: 'Nueva plantilla de página',
          component: PageTemplateFormComponent,
          idTemplate: '',
          idUSer: userId,
          type: 'form',
        },
      })
      .afterClosed()
      .subscribe((data) => {
        this.setTemplate(data['mainFields']).subscribe((data) => {
          this.templatesModified.next(true);
        });
      });
  }
}
