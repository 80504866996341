/*
 * File: core.module.ts                                                        *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 10:45:51                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { CcAutocompleteComponent } from './components/cc-autocomplete/cc-autocomplete.component';
import { CgLoaderComponent } from './components/cg-loader/cg-loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { FormGeneratorComponent } from './components/form-generator/form-generator.component';
import { LoginComponent } from '../catalog-cloud/components/user/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { UserRepeatedValidator } from './form-validators/user-repeated.validator';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    ModalDialogComponent,
    CcAutocompleteComponent,
    CgLoaderComponent,
    FormGeneratorComponent,
    NotFoundComponent
  ],
  imports: [
    MatDialogModule,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatListModule,
    MatInputModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatChipsModule,
    MatSelectModule,
    TranslateModule,
    MatTableModule,
    MatProgressBarModule

  ],
  exports: [
    ModalDialogComponent,
    CcAutocompleteComponent,
    CgLoaderComponent,
    FormGeneratorComponent,
  ],
  providers:[
    LoginComponent,
    UserRepeatedValidator
  ]
})
export class CoreModule {}
