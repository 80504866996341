/*
 * File: fascicle-form.schema.ts                                               *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 18th July 2022 15:30:47                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { FormSchema } from "src/app/core/interfaces/form-schema.interface";



export const fascicleFormSchema:FormSchema = {
  'mainFields': [
    {
      key: 'name',
      type: 'text',
      value: '',
      label: 'FASCICLE.NAME',
      required: true,
      order: 1,
      data: null,
    },

    {
      key: 'id',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 4,
      data: null,
    },
    {
      key: 'updateBy',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 5,
      data: null,
    },
    {
      key: 'updatedAt',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 6,
      data: null,
    },
    {
      key: 'createdBy',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 7,
      data: null,
    },
    {
      key: 'createdAt',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 8,
      data: null,
    },
    {
      key: 'position',
      type: 'hidden',
      value: '',
      label: 'Posición',
      required: false,
      order: 9,
      data: null,
    },
    {
      key: 'pages',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 10,
      data: null,
    },
    {
      key: 'blocked',
      type: 'hidden',
      required: false,
      value: '',
      label: 'Bloqueado',
      order: 11,
      data: null,
    },
    {
      key: 'blockedBy',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 12,
      data: null,
    },
    {
      key: 'idCatalog',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 13,
      data: null,
    },
    {
      key: 'categories',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 14,
      data: null,
    },
    {
      key: 'tags',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 15,
      data: null,
    },
    {
      key: 'totalPages',
      type: 'hidden',
      required: false,
      value: '',
      label: '',
      order: 15,
      data: null,
    },
  ],
  'extraFields': [],
  'categoryFields': [{
    key: 'categoriesAuto',
    type: 'autoComplete',
    value: '',
    label: 'FASCICLE.CATEGORIES',
    required: false,
    order: 2,
    data: {
      base: 'categories',
      selected: 'selectedCategories',
    },
  }],
  'tagFields': [{
    key: 'tagsAuto',
    type: 'autoComplete',
    value: '',
    label: 'FASCICLE.TAGS',
    required: false,
    order: 3,
    data: {
      base: 'tags',
      selected: 'selectedTags',
    },
  }],
};
