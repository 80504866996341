<!--
File: top-menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Wednesday, 9th March 2022 00:02:04
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<mat-toolbar class="mat-elevation-z7 top-toolbar" color="primary">
  <mat-toolbar-row style="padding-left: 0px !important" >
    <h1 [routerLink]="'/'"><img class="menu-img" src="./assets/img/nousmedis_logo.svg" /></h1>
    <app-breadcrumbs style="margin: 0 auto;"></app-breadcrumbs>

    <cc-actions-menu [context]="context"></cc-actions-menu>
    <app-user-menu *ngIf="this.user.logged" [user]="user"></app-user-menu>
  </mat-toolbar-row>
</mat-toolbar>
