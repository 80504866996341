<!--
 * File: page-delete.component.html                                            *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 25th May 2022 09:50:39                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 25th May 2022 11:09:16                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->



<p style="text-align: center;">{{ 'PAGE.DELETE_QUESTION' | translate }}</p>
