import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})

export class MatIconSvg {
  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
    ){
    this.matIconRegistry.addSvgIcon(
      `nousmedis_logo`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/nousmedis_logo.svg")
    )
    .addSvgIcon(
      `horizontal-resize`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/horizontal-resize.svg")
    );

  }
}
