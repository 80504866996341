/*
 * File: product-list-item.component.ts                                        *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 12th January 2022 13:27:37                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 15th June 2022 22:10:25                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/catalog-cloud/interfaces/product/product.interface';
import { ProductService } from 'src/app/catalog-cloud/services/product/product.service';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
})
export class ProductListItemComponent implements OnInit, OnDestroy {
  @Input() item: any;

  selected: boolean = false;
  showDetails: boolean = false;

  imSelected: Subscription = new Subscription();
  imageSource: string = '';
  elevation = '';

  constructor(private productService: ProductService) {}
  ngOnDestroy(): void {
    this.imSelected.unsubscribe();
  }

  ngOnInit(): void {
    // this.getImage();
    this.imSelected = this.productService.viewDetails.subscribe(
      (data: Product) => {
        if (data.id != this.item.id) this.selected = false;
        else this.selected = true;
      }
    );
  }

  select(item: any) {
    this.productService.selectedProduct(item);
  }

  async getImage() {
     const img = await fetch(this.item.img, { mode: 'no-cors' });
     console.log(img);
     const imgBlob = await img.blob();
     console.log(imgBlob);

  }

  addElevation() {
    this.elevation = 'mat-elevation-z5';
  }

  removeElevation() {
    this.elevation = '';
  }
}
