import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';


export const sidenaAnimation = {
  openClose:  trigger('openClose', [
    state('open',style({ width: '*'})),
    state('close',style({ width: '*'})),


    transition('open <=> close', animate(700))
  ])
}
