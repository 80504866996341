/*
 * File: list.component.ts                                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 3rd March 2022 15:43:37                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 10th March 2022 23:54:31                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observer, Subscription } from 'rxjs';
import { UserList } from 'src/app/catalog-cloud/interfaces/user/user-list.interface';
import { ApiService } from 'src/app/catalog-cloud/services/api.service';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit,OnDestroy {

  displayedColumns = [
    'name',
    'surname',
    'user',
    'role',
    'blocked',
    'actions'
  ]

  usersDataSource:MatTableDataSource<UserList> = new MatTableDataSource<UserList>();
  loading = true;

  changes:Subscription;

  constructor(private userService:UserService,private api: ApiService) { }
  ngOnDestroy(): void {
    this.stopListen();
  }

  ngOnInit( ): void {
    this.listenChanges();
    this.loadData();
  }

  loadData(){
    this.loading = true;
    this.userService.getUsers().subscribe(result => {
      let users:UserList[] = result;
      this.usersDataSource.data = users;
      this.loading = false
    })
  }

  listenChanges(){
   this.changes = this.userService.userChanged.subscribe(data =>{
      this.loadData();
    })
  }

  stopListen(){
    this.changes.unsubscribe();
  }

  reset(mail:string)
  {
    this.api.get('/users/recover/'+mail).subscribe((data)=>{
      console.log(data);
    });
    console.log(mail)
  }

  welcome(mail:string)
  {
    this.api.get('/users/welcome/'+mail).subscribe((data)=>{
      console.log(data);
    });
    console.log(mail)
  }

}
