/*
 * File: breadcumb.service.ts                                                  *
 * Project: catalog-cloud                                                      *
 * File Created: Tuesday, 8th March 2022 23:42:13                              *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 13th October 2022 10:34:40                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/internal/operators';

import { Breadcrumb } from 'src/app/catalog-cloud/interfaces/menus/breadcrumb.interface';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  private catalogId: string;
  private catalogName: string;
  private fascicles: any;
  private currentFasicleId: string;
  private position:any;

  constructor(private router: Router) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        // console.log("FIRSTCHILD",root.firstChild);
        this.getBreadcrumbInfo(root.firstChild);
        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  private getBreadcrumbInfo(route: ActivatedRouteSnapshot) {
    this.position = route.data;
    if (route.data.fascicle) {
      this.catalogId =
        route.data.fascicle.catalogId != null
          ? route.data.fascicle.catalogId
          : null;
      this.catalogName =
        route.data.fascicle.catalogName != null
          ? route.data.fascicle.catalogName
          : null;
      this.fascicles =
        route.data.fascicle.fascicles != null
          ? route.data.fascicle.fascicles
          : null;
      this.currentFasicleId =
        route.data.fascicle.currentFascicle != null
          ? route.data.fascicle.currentFascicle
          : null;
    }
    else if(route.data.catalog)
    {
      this.catalogId = route.data.catalog.catalogId;
      this.catalogName = route.data.catalog.catalogName;
      this.fascicles = route.data.catalog.fascicles;
      this.currentFasicleId = null;
    }
    else {
      this.catalogId = null;
      this.catalogName = null;
      this.fascicles = null;
      this.currentFasicleId = null;
    }
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[]
  ) {
    if (route.data) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
      // Add an element for the current route part
     // console.log(route.data);
      this.catalogId =
        route.data.catalogId != null ? route.data.catalogId : null;
      this.catalogName =
        route.data.catalogName != null ? route.data.catalogName : null;
      this.fascicles =
        route.data.fascicles != null ? route.data.fascicles : null;
      this.currentFasicleId =
        route.data.currentFasicleId != null
          ? route.data.currentFasicleId
          : null;
      if (route.data.breadcrumb) {
        const breadcrumb = {
          title: this.getLabel(route.data),
          url: '/' + routeUrl.join('/'),
        };
        breadcrumbs.push(breadcrumb);
      }

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  getPosition()
  {
    return this.position['breadcrumb'];
  }

  private getLabel(data: any) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.breadcrumb === 'function'
      ? data.breadcrumb(data)
      : data.breadcrumb;
  }

  getRoot() {
    return { id: this.catalogId, name: this.catalogName };
  }

  getFascicles() {
    return { currentId: this.currentFasicleId, fascicles: this.fascicles };
  }

  private parseBreadcrumb(data: any) {
    // switch (breadCumb) {
    //   case 'home':
    //     items.push({
    //       title: 'Listado de catálogos',
    //       link: '/',
    //     });
    //     break;
    //     //TODO: PASS TO ASYNC FUNCTION
    //   case 'fascicle':
    //     this.fascicleService
    //       .get(data.snapshot.params['idCatalog'], data.snapshot.params['id'])
    //       .subscribe((fascicle) => {
    //         this.catalogService
    //           .get(data.snapshot.params['idCatalog'])
    //           .subscribe((catalog) => {
    //             items.push({
    //               title: catalog['name'],
    //               link: '/',
    //             });
    //             items.push({
    //               title: fascicle['name'],
    //             });
    //           });
    //       });

    //     break;
    // }
    console.log('Lo tengo!');
  }
}
