import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { PopInfoService } from '../services/pop-info-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpNoCacheInterceptor implements HttpInterceptor {
  private pop: PopInfoService;
  constructor(private popService: PopInfoService) {
    this.pop = popService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const headers = req.clone();

    return next.handle(headers);
  }
}
