<!--
 * File: catalog-wizard-sync.component.html                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st June 2022 17:12:30                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->



<!-- <mat-stepper [linear]="true" #stepper [labelPosition]="'end'"  color="primary" >
  <ng-template matStepperIcon="edit">
    <mat-icon>done</mat-icon>
  </ng-template>
  <mat-step
    [stepControl]="checkDifferencesStep"
    [label]="checkDifferencesStepLabel"
    color="primary"
  >
    <form [formGroup]="checkDifferencesStep">
      <input type="hidden" formControlName="isValid" />
    </form>


    <div fxFill fxLayout="column" fxLayoutAlign="space-between center" >
      <app-catalog-sync-differences
      class="step-conent"
        [idCatalog]="idCatalog"
        (preSyncEmmiter)="isPreSync($event)"
      ></app-catalog-sync-differences>
    </div>

    <div fxFill fxLayoutAlign="space-between center"  class="step-footer">
      <span></span>
      <button
        mat-mini-fab
        [disabled]="!preSyncIsValid"
        (click)="goToSync()"
        color="primary"
      >
        <mat-icon>arrow_right</mat-icon>
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="syncStep" [label]="syncStepLabel">
    <form [formGroup]="syncStep">
      <input type="hidden" formControlName="isValid" />
    </form>
    <app-catalog-sync
      [idCatalog]="idCatalog"
      (syncFinish)="setSyncFinish()"
    ></app-catalog-sync>
    <div fxFill fxLayoutAlign="space-between center">
      <button mat-mini-fab matStepperPrevious color="primary">
        <mat-icon>arrow_left</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="primary"
        [disabled]="!syncIsValid"
        (click)="setCloseForm()"
      >
        <mat-icon>done</mat-icon>
      </button>
    </div>
  </mat-step>
</mat-stepper> -->
<app-catalog-sync-differences
  [idCatalog]="idCatalog"
  (preSyncEmmiter)="isPreSync($event)"
></app-catalog-sync-differences>
