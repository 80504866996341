/*
 * File: pop-info-service.service.ts                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 10th March 2022 23:15:09                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PopInfoService {
  constructor(private _snackBar: MatSnackBar) {}

  addMessage(severity: string, message: string, title?: string | null) {
    setTimeout(() => {

      this._snackBar.open(message, 'Ok', {
        duration: environment.pop_duration * 1000,
        panelClass:'pop-'+severity,



      });
    });
  }

  // addSuccess(message: string, title?: string | null) {
  //   this.addMessage('success',message,title);
  // }

  // addInfo(message: string, title?: string | null){
  //   this.addMessage('info',message,title);

  // }
  // addWarn(message: string, title?: string | null){
  //   this.addMessage('warn',message,title);
  // }

  // addError(message: string, title?: string | null){
  //   this.addMessage('error',message,title);
  // }

  // addMultiple(message: string, title?: string | null) {
  //   this.messageService.addAll([
  //     {
  //       severity: 'success',
  //       summary: 'Service Message',
  //       detail: 'Via MessageService',
  //     },
  //     {
  //       severity: 'info',
  //       summary: 'Info Message',
  //       detail: 'Via MessageService',
  //     },
  //   ]);
  // }

  // clear() {
  //   this.messageService.clear();
  // }
}
