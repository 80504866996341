/*
 * File: login-activate.interceptor.ts                                         *
 * Project: catalog-cloud                                                      *
 * File Created: Monday, 17th January 2022 17:58:27                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 24th March 2022 10:28:03                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class LoginActivate implements CanActivate {
  constructor(private authService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService) {
      this.router.navigate(['/login']);
    }
    else if (!this.authService.isLogged()) {
      this.router.navigate(['/login']);
    }
    return true;
  }
}
