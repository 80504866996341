<!--
File: catalog-list.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:06:54
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<app-cg-loader *ngIf="dataLoaded == false"> </app-cg-loader>

<div
  id="table-catalog"
  [class.disabled]="dataLoaded == true && dataCatalog.data.length > 0"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <h1 class="main-title">{{ "CATALOG.MY_CATALOGS" | translate }}</h1>
    <mat-form-field appearance="outline" class="search-field" color="primary" autocomplete="doNotAutoComplete">
      <input
        matInput
        type="text"
        placeholder="{{ 'CATALOG.SEARCH_CAT_FASC' | translate }}"
        autocomplete="doNotAutoComplete"
        (keyup)="applyFilter($event)"
      />
    </mat-form-field>
  </div>

  <div class="catalog-table-container mat-elevation-z5">
    <table
      mat-table
      matSort
      [dataSource]="dataCatalog"
      (matSortChange)="collapseAll()"
      multiTemplateDataRows
      [style.visibility]="dataLoaded ? 'visible' : 'hidden'"
    >
      <mat-header-row
        *matHeaderRowDef="['checkAll', 'paginator']"
        class="top-row"
      ></mat-header-row>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <ng-container matColumnDef="checkAll">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            id="select-all"
            color="primary"
            (change)="checkAllToogle($event)"
          ></mat-checkbox>
        </mat-header-cell>
      </ng-container>

      <ng-container matColumnDef="paginator">
        <mat-header-cell *matHeaderCellDef>
          <mat-paginator
            [pageSize]="10"
            [hidePageSize]="true"
            [style.visibility]="dataLoaded ? 'visible' : 'hidden'"
            fxFill
            aria-label="Select page"
          ></mat-paginator>
        </mat-header-cell>
      </ng-container>

      <ng-container matColumnDef="checkRow">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>

        <mat-cell *matCellDef="let catalog">
          <mat-checkbox
            color="primary"
            id="select-{{ catalog._id }}"
            (change)="rowChecked($event, catalog._id)"
            [checked]="selection.isSelected(catalog)"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "NAME" | translate | uppercase }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let catalog"
          [class.title-row-active]="catalog.isExpanded"
        >
          <h2
            class="catalog-title"
            [class.catalog-title-active]="catalog.isExpanded"
          >
            {{ catalog.name }}
          </h2>
          <span class="catalog-details" *ngIf="catalog.isExpanded">
            {{ catalog.createdBy.name }}
            {{ catalog.createdBy.surname | slice: 0:1 }}.,
            {{ catalog.updatedAt | date: "dd MMM YYYY - HH:mm" }},
            <span> {{ catalog["pages"] }} {{ 'PAGES' | translate }} </span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "CREATED_BY" | translate | uppercase }}
        </mat-header-cell>
        <mat-cell *matCellDef="let catalog">

          <span *ngIf="!catalog.isExpanded"
            >{{ catalog.createdBy.name }}
            {{ catalog.createdBy.surname | slice: 0:1 }}.</span
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "UPDATED_AT" | translate | uppercase }}
        </mat-header-cell>

        <mat-cell *matCellDef="let catalog">
          <span *ngIf="!catalog.isExpanded">{{
            catalog.updatedAt | date: "dd MMM YYYY - HH:mm"
          }}</span></mat-cell
        >
      </ng-container>

      <ng-container matColumnDef="pages">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ "PAGES" | translate | uppercase }}
        </mat-header-cell>
        <mat-cell *matCellDef="let catalog">
          <span *ngIf="!catalog.isExpanded">
            {{ catalog["pages"] }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandRow">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let catalog">
          <button
            mat-icon-button
            (click)="extendRow(catalog._id)"
            *ngIf="catalog.fascicles.length > 0"
          >
            <mat-icon id="btn-expand-{{ catalog._id }}" class="row-collapsed">{{
              icon_collapsed
            }}</mat-icon>
          </button>

          <button
            mat-icon-button
            (click)="newFascicle(catalog._id)"
            *ngIf="catalog.fascicles.length == 0"
          >
            <mat-icon id="btn-newFascicle-{{ catalog._id }}">
              auto_stories
            </mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <mat-cell
          *matCellDef="let catalog"
          [attr.colspan]="displayedColumns.length"
          class="catalog-fascicle-detail"
        >
          <app-fascicle-list
            [dataInput]="catalog.fascicles"
            [idCatalog]="catalog._id"
            [ngClass]="catalog.isExpanded ? 'expanded' : 'collapsed'"
            style="min-width: 100%"
          ></app-fascicle-list>
        </mat-cell>
      </ng-container>

      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.catalog-expanded-row]="row.isExpanded"
        [class.active]="row.isActive"
        class="remove-background double-border"
      ></mat-row>

      <mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="row-fascicles"
        fxFill
      ></mat-row>
    </table>
  </div>
</div>

<div
  id="new-catalog"
  fxLayout="row"
  fxLayoutAlign="center center"
  *ngIf="dataLoaded == true && dataCatalog.data.length == 0"
  class="new-catalog"
>
  <mat-card>
    <mat-card-title>
      {{ "CATALOG.EMPTY_MSG" | translate }}
    </mat-card-title>
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="openCatalog('')">
        {{ "CATALOG.CREATE" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</div>
