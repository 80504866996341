<!--
 * File: cc-cell-item-extra-fields-form.component.html                         *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 27th May 2022 09:15:01                                *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st June 2022 15:41:57                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->



  <!-- <mat-drawer  mode="side" opened>
    <mat-list>
      <mat-list-item  class="version-list" *ngFor="let version of entity.versions; let i = index"><div mat-line class="label version"> Version: {{i +1 }}</div> <div mat-line class="label date">Creada: {{version.createdAt | date}} </div></mat-list-item>
    </mat-list>
  </mat-drawer> -->


  <div fxFill fxLayout="column" fxLayoutAlign="center stretch" style="padding:1em !important;">
    <mat-form-field
      appearance="outline"
      class="full-witdth"
      *ngFor="let extra of entity; let i = index"
    >
      <mat-label>{{ extra.label }}</mat-label>
      <input matInput type="text" [(ngModel)]="extra.value" />
    </mat-form-field>
  </div>


