<!--
File: cc-cell-item-model-selector.component.html
Project: catalog-cloud
File Created: Wednesday, 2nd March 2022 09:47:48
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Sunday, 6th March 2022 00:04:46
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<button mat-icon-button [matMenuTriggerFor]="producModel" aria-label="Model type product">
 <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #producModel>
  <button mat-menu-item *ngFor="let item of formatedModels" (click)="changeModel(item)"><mat-icon *ngIf="item==modelSelected" color="primary">checked</mat-icon> {{item}}</button>
</mat-menu>
<!--
<mat-form-field appearance="fill" style="width: 100%;"  >
  <mat-select  [(value)]="modelSelected" (selectionChange)="changeModel($event.value)">
    <mat-option *ngFor="let item of formatedModels" value="{{item}}" s>{{item}}</mat-option>
  </mat-select>
</mat-form-field> -->
