/*
 * File: catalog-home.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 11:01:30                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */




import { Component, Input, OnInit } from '@angular/core';
import { sidenaAnimation } from 'src/app/core/animations/sidenav-animation';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-catalog-home',
  templateUrl: './catalog-home.component.html',
  styleUrls: ['./catalog-home.component.scss'],
  animations: [sidenaAnimation.openClose]
})
export class CatalogHomeComponent implements OnInit {

  isExpanded:boolean = false;
  constructor(
    translate:TranslateService
    ) {


     }

  ngOnInit(): void {

  }

}
