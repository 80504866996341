<!--
File: product-toolbar-search-info.component.html
Project: catalog-cloud
File Created: Sunday, 6th March 2022 23:00:15
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:52:46
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="buttons-toogle-container"
>
  <div fxLayout="column" fxLayoutAlign="space-between start">
    <!-- <span class="product-toobar-title-grey">Orden</span> -->
    <mat-button-toggle-group
      #order="matButtonToggleGroup"
      value="asc"
      (valueChange)="changeOrder()"
      class="border-toolbar"
    >
      <!-- <mat-button-toggle value="original" class="border-toolbar" matTooltip="{{'FASCICLE.ORDER_ORIGINAL' | translate}}"
        ><mat-icon class="icon-product-toolbar"
          >repeat</mat-icon
        ></mat-button-toggle
      > -->
      <mat-button-toggle value="asc" matTooltip="{{'FASCICLE.ORDER_ASCENDENT' | translate}}"
        ><mat-icon class="icon-product-toolbar"
          >arrow_upward</mat-icon
        ></mat-button-toggle
      >
      <mat-button-toggle value="desc" matTooltip="{{'FASCICLE.ORDER_DESCENDENT' | translate}}"
        ><mat-icon class="icon-product-toolbar"
          >arrow_downward</mat-icon
        ></mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between start">
    <small>{{ filteredProducts }}/{{ baseProducts }}</small>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between start">
    <!-- <span class="product-toobar-title-grey">Vista</span> -->
    <mat-button-toggle-group
      #productView="matButtonToggleGroup"
      value="list"
      class="border-toolbar"
      (valueChange)="changeViewType()"
    >
      <mat-button-toggle value="list" matTooltip="{{'FASCICLE.VIEW_LIST' | translate}}"
        ><mat-icon class="icon-product-toolbar"
          >view_list</mat-icon
        ></mat-button-toggle
      >
      <mat-button-toggle value="grid" matTooltip="{{'FASCICLE.GRID_LIST' | translate}}"
        ><mat-icon class="icon-product-toolbar"
          >grid_view</mat-icon
        ></mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
</div>
