<!--
File: create.component.html
Project: catalog-cloud
File Created: Thursday, 3rd March 2022 15:43:42
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:56:36
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="show" >
  <form
  [formGroup]="form"
  *ngIf="form"
  autocomplete="off"
  class="base-fascicle-form"
  >
  <div formGroupName="mainFields" class="full-width">


    <app-form-generator
    *ngFor="let field of orderedMainFields"
    [formGroup]="fbMain"
    [field]="field"
  ></app-form-generator>
</div>

  </form>

</div>

