/*
 * File: auth-interceptor.ts                                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 1st February 2022 16:36:17                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  userCookie:string = 'ccuser'

  constructor(private cookieService:CookieService){}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = JSON.parse(this.cookieService.get(this.userCookie))['token']

    if (token)
    {
      const headers = req.clone({
        headers: req.headers.set('api-key', `Bearer ${token}`),
      });
      return next.handle(headers);
    }
    else{
      return next.handle(req);
    }


  }
}
