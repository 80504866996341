/*
 * File: fascicle.service.ts                                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 18th July 2022 15:26:36                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { CookieService } from 'ngx-cookie-service';

import { Subject } from 'rxjs';

import { ModalDialogComponent } from 'src/app/core/components/modal-dialog/modal-dialog.component';
import { PopInfoService } from 'src/app/core/services/pop-info-service.service';

import { ApiService } from '../api.service';

import { FascicleFormComponent } from '../../components/fascicle/fascicle-form/fascicle-form.component';
import { Fascicle } from '../../interfaces/fascicle/fascicle.interface';
import { ActionsMenuService } from '../menus/actionsMenu.service';
import { PageService } from '../page/page.service';
import { UserService } from '../user/user.service';
import { FascicleDeleteComponent } from '../../components/fascicle/fascicle-delete/fascicle-delete.component';
import { TranslateService } from '@ngx-translate/core';

export interface IBlock {
  blockedBy?: string;
  blockedAt?: string;
}
@Injectable()
export class FascicleService {
  /**
   * Endpoint list fascicles: /catalog/:idCatalog/fascicles
   *
   */
  endpoint: string = '/catalog/:idCatalog/fascicles';
  endpointCatalog: string = '/catalogs';

  private hiddenFields = ['id'];
  private mainFields = ['name', 'categories', 'tags', 'dataFields'];

  // Behaviour properties for edit properties and create fascicle

  loadingFascicle: Subject<boolean> = new Subject<boolean>();

  dataFascicles: Subject<Fascicle[]> = new Subject<Fascicle[]>();
  dataTableFascicles: Subject<MatTableDataSource<Fascicle>> = new Subject<
    MatTableDataSource<Fascicle>
  >();

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private popInfoService: PopInfoService,
    private actionMenuService: ActionsMenuService,
    private modalDialog: MatDialog,
    private trans:TranslateService
  ) {}

  private getBaseEndPoint(idCatalog: string) {
    return '/catalog/' + idCatalog + '/fascicles';
  }

  openForm(id: string, idCatalog: string) {
    return this.dialog.open(ModalDialogComponent, {
      width: 'auto',
      height: 'auto',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        title: id.length > 0 ? this.trans.instant("FASCICLE.EDIT_FASCICLE") : this.trans.instant("FASCICLE.NEW_FASCICLE"),
        component: FascicleFormComponent,
        id: id,
        idCatalog: idCatalog,
        type: 'form',
      },
    });
  }

  fetch(idCatalog: string) {
    return this.api.get(this.getBaseEndPoint(idCatalog));
  }

  get(idCatalog: string, id: string) {
    return this.api.get(this.getBaseEndPoint(idCatalog) + '/' + id);
  }

  save(fascicle: Fascicle) {
    if (fascicle.id.length == 0) {
      delete fascicle.id;
      return this.api.post(this.endpoint, fascicle);
    } else {
      return this.api.put(this.endpoint + '/' + fascicle.id, fascicle);
    }
  }

  delete(idCatalog: string, id: string) {
    const endpoint = this.getBaseEndPoint(idCatalog) + '/' + id;
    return this.api.delete(endpoint);
  }

  clone(idCatalog: string, idFascicle: string) {
    let body = { modelFascicleReference: idFascicle };
    const endpoint = this.getBaseEndPoint(idCatalog) + '/clone/' + idFascicle;
    return this.api.post(endpoint, body);
  }

  /**
   *
   * @param data Form data provided by hiddenFields and mainfields (Mainfields has the data, hiddenfields the id)
   */
  saveProperties(data: any) {
    let id = data['mainFields']['id'];
    let idCatalog = data['mainFields']['idCatalog'];
    let allData = Object.assign(
      data['mainFields'],
      data['extraFields'],
      data['autoFillFields']
    );
    let url = this.getBaseEndPoint(idCatalog);
    if (id != '') {
      return this.api.patch(url + '/' + id, allData);
    } else {
      let url = this.getBaseEndPoint(idCatalog);
      return this.api.post(url, allData);
    }
  }

  initialize(): Fascicle {
    return {
      name: '',
      id: '',
      createdBy: null,
      updatedBy: null,
      categories: [],
      tags: [],
      blocked: false,
      extra: null,
      pages: null,
      position: 0,
      totalPages: 0,
      blockedBy: null,
    };
  }

  updateFascicleOrder(idCatalog: string, fascicles: Fascicle[]) {
    const endPoint = this.getBaseEndPoint(idCatalog);
    const totalFascicles = fascicles.length;
    let allLoaded = new Subject();
    let currentLoad = 0;
    allLoaded.subscribe((data) => {
      if (data == totalFascicles) {
        this.api
          .post(this.endpointCatalog + '/recalculate_pages/' + idCatalog, null)
          .toPromise();
      }
    });

    for (let i = 0; i < fascicles.length; i++) {
      fascicles[i].position = i + 1;
      this.api
        .patch(endPoint + '/' + fascicles[i].id, {
          position: fascicles[i].position,
        })
        .subscribe((data) => {
          currentLoad = currentLoad + 1;
          allLoaded.next(currentLoad);
        });
    }
  }

  getFasciclesOrdered(fascicles: Fascicle[]) {
    return fascicles.sort((a, b) =>
      a.position > b.position ? 1 : b.position > a.position ? -1 : 0
    );
  }

  setFromToPages(fascicles: Fascicle[]) {
    fascicles = this.getFasciclesOrdered(fascicles);
    let current = 0;
    for (let i = 0; i < fascicles.length; i++) {
      if (i == 0) {
        if (fascicles[i].totalPages == 0) {
          fascicles[i].fromto = '_EMPTY_';
        } else {
          fascicles[i].fromto = '1-' + fascicles[i].totalPages;
        }
      } else {
        if (fascicles[i].totalPages == 0) {
          fascicles[i].fromto = fascicles[i].fromto = '_EMPTY_';
        } else {
          fascicles[i].fromto =
            current + 1 + '-' + (current + fascicles[i].totalPages);
        }
      }

      current += fascicles[i].totalPages;
    }
    return fascicles;
  }

  editFascicleProperties(id: string, catalogId: string) {
    this.openForm(id, catalogId)
      .afterClosed()
      .subscribe((formData) => {
        if (formData != false) {
          this.loadingFascicle.next(true);
          //this.loading =true;
          this.saveProperties(formData).subscribe((data) => {
            this.fetch(catalogId).subscribe((fascicles) => {
              if (formData['mainFields']['id'].length > 0)
                this.popInfoService.addMessage(
                  'Info',
                  this.trans.instant("FASCICLE.UPDATED")
                );
              else this.popInfoService.addMessage('Info', this.trans.instant("FASCICLE.CREATED"));
              let data = this.setFromToPages(fascicles);
              let source = new MatTableDataSource(data);
              this.dataFascicles.next(data);
              this.dataTableFascicles.next(source);
              this.loadingFascicle.next(false);
              this.actionMenuService.reset();
            });
          });
        }
      });
  }

  newFascicle(catalogId: string) {
    this.openForm('', catalogId)
      .afterClosed()
      .subscribe((formData) => {
        if (formData != false) {
          this.loadingFascicle.next(true);
          this.saveProperties(formData).subscribe((data) => {
            this.fetch(catalogId).subscribe((fascicles) => {
              if (formData['mainFields']['id'].length > 0)
                this.popInfoService.addMessage(
                  'Info',
                  this.trans.instant("FASCICLE.UPDATED")
                );
              else {
                this.popInfoService.addMessage('Info',  this.trans.instant("FASCICLE.CREATED"));
              }
              let data = this.setFromToPages(fascicles);
              let source = new MatTableDataSource(data);
              this.dataFascicles.next(data);
              this.dataTableFascicles.next(source);
              this.loadingFascicle.next(false);
              this.actionMenuService.reset();
            });
          });
        }
      });
  }

  block(idCatalog: string, id: string) {
    let url = this.getBaseEndPoint(idCatalog) + '/' + id + '/block';
    this.api.patch(url, null).subscribe((data) => {});
  }

  unblock(idCatalog: string, id: string) {
    let url = this.getBaseEndPoint(idCatalog) + '/' + id + '/unblock';
    this.api.patch(url, null).subscribe((data) => {});
  }

  async getBreadCrumb(idCatalog: string, id: string) {
    const endpointCatalog: string = '/catalogs/';
    let catalog;
    let fascicle;
    const c = await this.api.get(endpointCatalog + idCatalog).toPromise();
    const f = await this.get(idCatalog, id).toPromise();
    let fascicles = c.fascicles.map((f) => {
      return { id: f.id, name: f.name };
    });
    return {
      breadcrumb:'fascicle-edit',
      catalogId: c.id,
      catalogName: c.name,
      fascicles: fascicles,
      currentFascicle: id,
    };
  }

  deleteModal(idCatalog:string,idFascicle:string) {
    return this.modalDialog
      .open(ModalDialogComponent, {
        width: 'auto',
        height: 'auto',
        panelClass: 'mat-elevation-z8',
        data: {
          title:  this.trans.instant("FASCICLE.REMOVE_FASICLE"),
          component: FascicleDeleteComponent,
          idCatalog:idCatalog,
          idsFascicle: idFascicle,
          type: 'yesno',
        },
      })
      .afterClosed();

  }

}
