/*
 * File: http-error.interceptor.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 15th March 2022 11:24:16                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { PopInfoService } from '../services/pop-info-service.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private pop: PopInfoService;
  constructor(private popService: PopInfoService) {
    this.pop = popService;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error) => {
        let errorMessage = '';
        if (typeof error === 'string') {
          this.popService.addMessage('warn', error, 'Api Error');
        } else {
          if (error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Client-side error: ${error.error.message}`;
          } else {
            // backend error
            errorMessage = `Server-side error: ${error.status} -- ${error.message} `;
          }
          //this.popService.addMessage('warn', errorMessage, 'Api Error');
        }

        console.log(error);
        // aquí se podría agregar código que muestre el error en alguna parte fija de la pantalla.
        // if (environment.production== false){
        //   this.popService.addMessage('warn',errorMessage,'Api Error');
        //   console.log(error);
        // }
        return throwError(errorMessage);
      })
    );
  }
}
