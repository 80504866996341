/*
 * File: user-register.schema.ts                                               *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 3rd March 2022 17:37:21                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 24th March 2022 09:50:28                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Validators } from '@angular/forms';
import { mailValidator } from 'src/app/core/form-validators/email.validator';
import { passwordStrengthValidator } from 'src/app/core/form-validators/password-strength.validator';
import { sameAsValidator } from 'src/app/core/form-validators/same-as.validator';
import { FormSchema } from 'src/app/core/interfaces/form-schema.interface';
import { langs } from 'src/assets/i18n/available-lang';

//Estaria bien que esto fuera ina interface
export const userEditSchema: FormSchema = {
  mainFields: [
    {
      key: 'id',
      type: 'hidden',
      value: 'false',
      label: '',
      required: true,
      order: 1,
      data: null,
    },
    {
      key: 'name',
      type: 'text',
      value: '',
      label: 'NAME',
      required: true,
      order: 1,
      data: null,
    },
    {
      key: 'surname',
      type: 'text',
      value: '',
      label: 'USER.SURNAME',
      required: true,
      order: 2,
      data: null,
    },
    {
      key: 'lang',
      type: 'select',
      value: '',
      label: 'USER.SELECT_LANG',
      required: true,
      order: 3,
      data: {
        base:
          langs.map((l) => {
            return { 'label': l.lang, 'value': l.value };
          })
        ,
        selected: 'lang',
      },
    },
  ],
};

// export const userRegisterSchema = {
//   mainFields: [
//     {
//       key: 'blocked',
//       type: 'hidden',
//       value: 'false',
//       label: '',
//       required: true,
//       order: 1,
//       data: null,
//     },
//     {
//       key: 'username',
//       type: 'text',
//       value: '',
//       label: 'Correo electrónico',
//       required: true,
//       order: 3,
//       data: null,
//     },
//     {
//       key: 'password',
//       type: 'password',
//       value: '',
//       label: 'Contraseña',
//       required: true,
//       order: 4,
//       data: null,
//     },
//     {
//       key: 'password2',
//       type: 'password',
//       value: '',
//       label: 'Repetir contraseña',
//       required: true,
//       order: 4,
//       data: null,
//     },
//     {
//       key: 'name',
//       type: 'text',
//       value: '',
//       label: 'Nombre',
//       required: true,
//       order: 1,
//       data: null,
//     },
//     {
//       key: 'surname',
//       type: 'text',
//       value: '',
//       label: 'Apellidos',
//       required: true,
//       order: 2,
//       data: null,
//     },
//     {
//       key: 'role',
//       type: 'select',
//       value: '',
//       label: 'Rol',
//       required: true,
//       order: 5,
//       data: { base: 'tags', selected: 'roleSelection' },
//     },
//   ],
// };
