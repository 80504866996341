<!--
File: cc-grid.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Thursday, 10th March 2022 12:02:42
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div
  fxLayout="column"
  fxLayoutAlign="space-between center"
  *ngIf="!loading"
  class="page-view"
>
  <div class="header-grid">
    <app-cc-grid-selector
      (locked)="lock($event)"
      (newGridFormat)="changeFormat($event)"
      (saveFormat)="saveGridFormat($event)"
      class="grid-selector"
      [lock]="initialLockButtos"
      [grids]="baseGrids"
      [currentGrid]="currentPageTemplate"
      [pageId]="page.id"
    ></app-cc-grid-selector>
  </div>
  <div class="wrap-grid">
    <div
      *ngIf="page"
      class="grid"
      id="page-{{ page.id }}"
      [ngStyle]="grid.getGridStyle()"
    >
      <ng-container *ngFor="let cell of grid.cells">
        <app-cc-cell
          *ngIf="cell.data.visible"
          [cellId]="grid.cells.indexOf(cell)"
          [grid]="grid"
          [ngStyle]="cell.getStyle()"
          [idCatalog]="idCatalog"
          [idFascicle]="idFascicle"
          [idPage]="idPage"
          [locked]="lockedButtons"
        ></app-cc-cell>
      </ng-container>
    </div>
  </div>
  <div
    [ngClass]="isOdd ? 'footer-page-right' : 'footer-page-left'"
    class="footer-page"
  >
    <span>{{ page.pageNumber }}</span>
  </div>
</div>

<div class="logging" *ngIf="logging">
  <div fxFill>
    <!-- <div *ngFor="let cell of grid.cells">
      <ul>
        <li>
          <span
            >Id <b>{{ cell.data.cellNumber }}</b></span
          >
          <ul>
            <li>
              Cols: <b>{{ cell.data.columnWith }}</b> . Rows:
              <b>{{ cell.data.rowWidth }}</b>
            </li>
            <li>
              Parent cell:{{
                cell.parentCell != null ? cell.parentCell.cellNumber : "-"
              }}
            </li>
            <li>
              Joined cells:
              <b *ngFor="let item of cell.getJoinedCells()"
                >{{ item.cellNumber }}
              </b>
            </li>
          </ul>
        </li>
      </ul>
    </div> -->
    {{idPage}}
  </div>
</div>
