/*
 * File: create.component.ts                                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 3rd March 2022 15:43:42                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 15th March 2022 11:53:30                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { AfterContentChecked, ChangeDetectionStrategy, Component,  OnInit, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { userRegisterSchema } from 'src/app/catalog-cloud/schemas/user/user-register.schema';
import { FormCreator } from 'src/app/core/helpers/form-creator';
import { sameAsValidator } from 'src/app/core/form-validators/same-as.validator';
import { UserRepeatedValidator } from 'src/app/core/form-validators/user-repeated.validator';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CreateComponent implements OnInit,AfterContentChecked {

  form!: FormGroup;
  fbMain!:FormGroup;
  show:boolean = false;
  orderedMainFields = userRegisterSchema.mainFields.sort(
    (a, b) => a.order - b.order
  );
  userRepeatedValidator:UserRepeatedValidator;
  constructor(private fb:FormBuilder,private userService:UserService,private translate:TranslateService) {
    this.userRepeatedValidator = new UserRepeatedValidator(userService,translate);

   }

  ngOnInit(): void {

    this.show =  false;
    const fc = new FormCreator(this.fb, userRegisterSchema);
    this.form = fc.getControls();
    this.fbMain = this.form.controls['mainFields'] as FormGroup;
    this.fbMain.addValidators(sameAsValidator('password','password2'))
    this.fbMain.addValidators(this.userRepeatedValidator.isValid('username'))

    this.form.setErrors({start:true})
    this.show = true;

  }

  ngAfterContentChecked(): void {

  }


}
