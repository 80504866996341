/*
 * File: modal-dialog.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 25th March 2022 11:00:42                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
  @ViewChild('target', { read: ViewContainerRef, static: true })
  vcRef: ViewContainerRef;

  componentRef: ComponentRef<any>;
  title: string;
  okButtonEnabled: boolean = false;
  type:string;
  loaded:boolean = false;
  waiter:boolean = false;
  progressEvery:number;
  defaultResponse:boolean;

//Opciones para el progreso de espera en el caso que exista
  progessValue: number = 0;
  offsetValue: number = 10;
  maxProgress: number = 100;
  intervalId: any = null;

  barValue:Subject<number> = new Subject<number>();

  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    private resolver: ComponentFactoryResolver,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  instanceKey: string;

  //Enviar inputs dentro del componente referencial al iniciar el componente de modales
  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);

    for (let key in this.data) {
      switch(key)
      {
        case 'component':

        break;
        case 'title':
          this.title = this.data[key];
          break;
        case 'type':
          this.type = this.data[key];
          break;
        case 'waiter':
          this.waiter = this.data[key];
          break;
        case 'progressEvery':
          this.progressEvery = this.data[key];
          break;
        case 'defaultResponse':
          this.defaultResponse = this.data[key];
          break;
        default:
          this.instanceKey = key;
          this.componentRef.instance[key] = this.data[key];
        break;
      }

      if (this.componentRef.instance.closeForm)
      {
        this.componentRef.instance.closeForm.subscribe((test)=>{
          this.dialogRef.close(true)
        })
      }

    }

    let that = this;
    setTimeout(function(){
      that.loaded = true;
      if(that.waiter) {
        that.waitForRequest()
        that.barValue.next(that.offsetValue)
      }
    }
    ,250)
  }




  waitForRequest() {
    let that= this;
    this.barValue.subscribe(value => {
      that.progessValue = value;
      if (value < that.maxProgress){
        setTimeout(function(){
          that.barValue.next(value + that.offsetValue)
        },1000);
      }
      else{
        that.okClick();
      }
    })
  }

  /**
   * Si tiene formulario desactiva el botón de Ok hasta que esté válido
   */

  hasForm() {
    // console.log(this.componentRef.instance.form);
    if (
      this.componentRef.instance.formIsValid != null &&
      typeof this.componentRef.instance.formIsValid != 'undefined'
    ) {
      this.okButtonEnabled = this.componentRef.instance.formIsValid;
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  okClick(): any {
    //    console.log(this.componentRef.instance.form);
    if (typeof(this.componentRef.instance.form) !== 'undefined')
    {
      if (this.componentRef.instance.form.status === 'VALID') {
        //console.log(this.componentRef.instance.form.value);
        this.dialogRef.close(this.componentRef.instance.form.value);
      }
    }
    else if(typeof(this.componentRef.instance.entity) !== 'undefined'){
      this.dialogRef.close(this.componentRef.instance.entity)
    }
    else{
      this.dialogRef.close(true)
    }

  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
