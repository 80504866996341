/*
 * File: form-creator.ts                                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 4th March 2022 09:52:50                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */





//Helper importante!!!

/**
 * Recivbe un objeto de tipo FormBuilder y otro de tipo
 * FormSchema (Propio de Catalog Cloud) y convierte el objeto en un formulario.
 * Pendiente
 *  - Generación de validaciones
 */


import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FindValueOperator } from 'rxjs/internal/operators/find';
import { FieldInterface } from '../components/form-generator/interfaces/field.interface';



export class FormCreator {
  private groups: string[] = [];
  private schema: Object;
  private fb: FormBuilder;

  constructor(fb: FormBuilder, schema: Object) {
    this.fb = fb;
    this.schema = schema;
    this.setGroups();
  }

  getControls(): FormGroup {
    try{
      let form:FormGroup = this.fb.group({});
    this.groups.forEach((group) => {
      if (group != 'default') {
        let formGroup: any = {};
        this.schema[group].forEach((field:FieldInterface) => {
          formGroup[field.key] = this.setControl(field);
        });
        form.addControl(group,new FormGroup(formGroup));
      }
    });
    return form;
    }
    catch(e)
    {
      console.error(e);
      return undefined;
    }

  }

  setGroups() {
    this.groups = Object.keys(this.schema);
  }

  getFields(group: string): string[] {
    return Object.keys(this.schema[group]);
  }

  setControl(data: FieldInterface) {
    let fc = new FormControl(data.value ? data.value : '');
    if (data.required)
      fc.setValidators(Validators.required);

      if (data.validators)
      data.validators.forEach(val => {
        fc.setValidators(val)
      })
    // if (data.val)
    return fc;

  }
}
