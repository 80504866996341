<!--
File: menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 19:05:35
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


<button
  mat-flat-button
  color="primary"
  [matMenuTriggerFor]="menu"
  aria-label="user-menu-button"
>
<mat-icon matPrefix>account_circle</mat-icon>
<span>   {{ user.name }}</span>
  <mat-icon matSuffix>expand_more</mat-icon>
</button>

<mat-menu #menu="matMenu"  >
  <button mat-menu-item>
    <mat-icon matPrefix>person</mat-icon>
    <span>{{ user.name }}</span>
  </button>

  <button mat-menu-item (click)="editMe()">
    <mat-icon matPrefix>edit</mat-icon>
    <span>{{ 'USER.EDIT_MY_DATA' | translate }} </span>
  </button>

  <button mat-menu-item *ngIf="user.role == 2" [routerLink]="'/users/list/'">
    <mat-icon matPrefix>people_black</mat-icon>
    <span>{{ 'USER.ADMIN_USERS' | translate }} </span>
  </button>

  <mat-divider  *ngIf="user.role == 2" > </mat-divider>
  <button mat-menu-item *ngIf="user.role == 2" [routerLink]="'/page-templates/list/'">
    <mat-icon matPrefix>web</mat-icon>
    <span>{{ 'PAGE.TEMPLATE_LIST' | translate }} </span>
  </button>

  <button mat-menu-item *ngIf="user.role == 2" [routerLink]="'/backups/list/'">
    <mat-icon matPrefix>settings_backup_restore</mat-icon>
    <span>{{ 'BACKUPS' | translate }} </span>
  </button>


  <mat-divider  *ngIf="user.role == 2"></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>{{ 'USER.CLOSE_SESSION' | translate }} </span>
  </button>




</mat-menu>
