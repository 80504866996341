<!--
File: product-grid.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Tuesday, 8th March 2022 18:04:18
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div
  class="product-grid"

>
  <div
    class="product-grid-item-container"
    *ngFor="let item of items"
    cdkDropList
  >
    <app-product-grid-item [item]="item"></app-product-grid-item>
  </div>
</div>
