import { CcGridCell } from './cc-grid-cell.model';
import { PageCell } from '../../interfaces/fascicle/page-cell.interface';

export class CcGrid {
  rows: number = 0;
  columns: number = 0;
  totalCells: number = 0;
  cells: CcGridCell[] = [];

  constructor(rows: number, columns: number) {
      this.rows = rows;
      this.columns = columns;
      this.recalcCells();
  }

  setGrid(cells:PageCell[])
  {
    let that = this;
    this.cells = [];
    cells.forEach(cell => {
      this.cells.push(new CcGridCell(cell,that));
    })
  }


  setRows(rows: number) {
    this.rows = rows;
    this.recalcCells();
  }

  setColumns(columns: number) {
    this.columns = columns;
    this.recalcCells();
  }

  recalcCells() {
    this.totalCells = this.rows * this.columns;
  }

  getGridStyle() {
    return {
      'grid-template-columns': `repeat(${this.columns},minmax(0,1fr))`,
      'grid-auto-columns':`minmax(0,1fr)`,
      'grid-auto-flow':`column`,
      'grid-template-rows': `repeat(${this.rows},minmax(0,1fr))`,
    };
  }
}
