/*
 * File: fascicle-edit.component.ts                                            *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 13th October 2022 10:35:01                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { EsPaginatorIntl } from 'src/app/core/helpers/es-paginator-intl';
import { ActivatedRoute } from '@angular/router';

import { FascicleService } from 'src/app/catalog-cloud/services/fascicle/fascicle.service';

import { keyValue } from 'src/app/core/interfaces/key-value';

import { Fascicle } from 'src/app/catalog-cloud/interfaces/fascicle/fascicle.interface';
import { Page } from 'src/app/catalog-cloud/interfaces/fascicle/page.interface';
import { PageTemplate } from 'src/app/catalog-cloud/interfaces/fascicle/page-template.interface';

import { PageService } from 'src/app/catalog-cloud/services/page/page.service';
import { PageTemplateService } from 'src/app/catalog-cloud/services/page/page-template.service';

import { Paginator } from 'src/app/catalog-cloud/helpers/paginator';
import { ApiService } from 'src/app/catalog-cloud/services/api.service';
import { CookieService } from 'ngx-cookie-service';
// import { User } from 'src/app/core/user/user';
import { GridLocked } from 'src/app/catalog-cloud/interfaces/grid/grid-locked.interface';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { FascicleSocketService } from 'src/app/catalog-cloud/services/fascicle/fascicle-socket.service';
import { ProductService } from 'src/app/catalog-cloud/services/product/product.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-fascicle-edit',
  templateUrl: './fascicle-edit.component.html',
  styleUrls: ['./fascicle-edit.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: EsPaginatorIntl }],
})
export class FascicleEditComponent implements OnInit, OnDestroy {
  @Input() idFascicle: string;
  @Input() idCatalog: string;

  loading: boolean = true;

  pageServiceSubscription = new Subscription();
  pageTemplateServiceSubscription = new Subscription();
  paginationIndexSubscription = new Subscription();

  allPageTemplates: PageTemplate[] = [];
  currentPageTemplate: PageTemplate = null;
  fascicle: Fascicle;
  pages: Page[] = [];
  visiblePages: Page[] = [];
  gPage: Paginator;

  currentPageIndex: number[] = null;
  currentNumberPages: number[] = null;

  /**Initial data */
  paginatorData = {
    totalPages: 0, //  Changes when page added or remove
    size: 1, //  Qty of pages viewed
    index: 0,
  };

  /**For style pourpouses */
  basePageColumn: number = 80;
  sizePage: number;

  productFilter: keyValue[] = [];
  gridLocked: GridLocked = { index: -1, locked: true };

  user: IUser;

  @ViewChild('buttonMenuPages') buttonMenuPages: MatMenuTrigger;
  @ViewChild('selectPageAfter') selectPageAfter: MatSelect;
  @ViewChild('selectPageBefore') selectPageBefore: MatSelect;

  //goToIndex:number = 0;

  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private fascicleService: FascicleService,
    private fascicleSockets: FascicleSocketService,
    private pageService: PageService,
    private pageTemplateService: PageTemplateService,
    private userService: UserService,
    private productService: ProductService
  ) {}

  ngOnInit() {
    this.productService.offsetSearch = 0;
    this.user = this.userService.setUser();
    this.idFascicle = this.route.snapshot.params.id;
    this.idCatalog = this.route.snapshot.params.idCatalog;
    this.loadPageTemplates();
    this.listenPageService();
  }

  listenPageService() {

    this.pageServiceSubscription = this.pageService.pagesSubject.subscribe(
      (pages) => {
        // console.log("El indice para ir a la página es:",this.pageService.goToIndex);
        this.pages = pages;
        this.paginatorData.totalPages = pages.length;
        this.loadNumberPages();
        if (this.gPage) {
          // console.log("Si que existe gPage")
          this.gPage.reload(this.pages.map((p) => p.pageNumber));
          this.gPage.reloadCombo();
          this.gPage.goToPage(this.pageService.goToIndex);
          //this.gPage.goToLast();
        }
      }
    );
  }

  loadPageTemplates() {
    this.pageTemplateServiceSubscription = this.pageTemplateService
      .getTemplates()
      .subscribe(async (pageTemplates) => {
        this.pageService.setPageTemplates(pageTemplates);
        this.allPageTemplates = pageTemplates;
        this.loadFascicle();
      });
  }

  loadPagination() {
    this.gPage = new Paginator(
      this.pages.map((p) => p.pageNumber),
      0,
      2
    );
    this.paginatorData.totalPages = this.gPage.totalPages;
    this.currentPageIndex = this.gPage.currentPage();
    this.paginationIndexSubscription = this.gPage._index.subscribe((index) => {
      this.currentPageIndex = index;
    });
    this.gPage.getComboPagination();
    this.loading = false;
  }

  loadNumberPages() {
    this.currentNumberPages = [];
    this.pages.forEach((page) => {
      this.currentNumberPages.push(page.pageNumber);
    });
  }
  /**
   * 1.- Carga el fasciculo
   * 2.- Por cada página carga las celdas.
   */

  loadFascicle() {
    this.loading = true;
    this.fascicleService
      .get(this.idCatalog, this.idFascicle)
      .subscribe((fascicle) => {
        this.fascicle = fascicle;
        this.pageService.idCatalog = this.idCatalog;
        this.pageService.idFasiclce = this.idFascicle;
        this.fascicleService.block(this.idCatalog, this.idFascicle);
        this.fascicleSockets.sendChanges;
        let that = this;
        setTimeout(function () {
          that.fascicleSockets.sendChanges();
        }, 1000);
        this.setFilters();
        if (!this.fascicle.pages) {
          this.addPage();
        } else {
          this.pageService.setPages(fascicle.pages);
        }

        this.loadPagination();
      });
  }

  addPage() {
    this.pageService.goToIndex = this.pages.length;
    this.pageService.addPage(this.user.id);
  }

  addTheFirstPage(){
    this.pageService.addPage(this.user.id);

  }

  addPageFirst() {
    this.pageService.goToIndex = 0;
    this.pageService.addPageFirst(this.user.id);
  }

  addPageBefore(pageNumber: number, trigger: MatMenuTrigger) {
    trigger.closeMenu();
    this.selectPageBefore.value = null;
    this.pageService.goToIndex = this.pages.map(p=> {return p.pageNumber}).indexOf(pageNumber);
    this.pageService.addPageBefore(this.user.id, pageNumber);
  }

  addPageAfter(pageNumber: number, trigger: MatMenuTrigger) {
    trigger.closeMenu();
    this.selectPageAfter.value = null;
    this.pageService.goToIndex = this.pages.map(p=> {return p.pageNumber}).indexOf(pageNumber) + 1;

    this.pageService.addPageAfter(this.user.id, pageNumber);
  }

  // reformatPage(format: PageTemplate, pageIndex: number) {
  //   this.pageService.setCells(pageIndex, format, this.user.id);
  // }

  getPageTemplate(pageIndex: number) {
    return this.pageService.getPageTemplate(pageIndex);
  }

  setFilters() {
    if (this.fascicle) {
      if (this.fascicle.categories)
        this.productFilter.push({
          id: 'categories',
          value: this.fascicle.categories,
        });
      if (this.fascicle.tags)
        this.productFilter.push({ id: 'tags', value: this.fascicle.tags });
    }
  }

  /**
   * Pages behaviour
   */

  getPageSize() {
    this.sizePage = this.basePageColumn / this.paginatorData.size;
  }

  nextDisabled() {
    return this.paginatorData.index >= this.paginatorData.totalPages - 1;
  }

  backDisabled() {
    return this.paginatorData.index <= 0;
  }

  async checkLocked(gridLocked: GridLocked) {
    if (gridLocked.locked) {
      await this.pageService.savePages();
    }
    this.gridLocked = gridLocked;
  }

  changeFormat(format: PageTemplate, pageIndex: number) {
    this.pageService.setGrid(pageIndex, format, this.user.id);
  }

  pageIsOdd(pageN: number) {
    if (this.pages && this.pages[pageN]) {
      this.pages[pageN].pageNumber;
      return this.pages[pageN].pageNumber % 2 == 1;
    }
    return true;
  }

  /**
   * End Pages behaviour
   */
  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.fascicleService.unblock(this.idCatalog, this.idFascicle);
    this.fascicleSockets.sendChanges();
    this.pageServiceSubscription.unsubscribe();
    this.pageTemplateServiceSubscription.unsubscribe();
    this.paginationIndexSubscription.unsubscribe();
  }
}
