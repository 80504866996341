import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fascicle-delete',
  templateUrl: './fascicle-delete.component.html',
  styleUrls: ['./fascicle-delete.component.scss']
})
export class FascicleDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
