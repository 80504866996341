import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTagsService {
  private baseUrl = '/catalog/:id/tags';

  private pagination = false;
  private pagintaionParam = 'pagination=' + this.pagination;
  constructor(private api:ApiService) { }

  getUrl(idCatalog:string ){
    var regex = /:(\w+)/g;
    return this.baseUrl.replace(regex, function(match, p1) {
        return idCatalog || ':' + p1;
    });
  }

  getByCatalog(id:string){
    return this.api.get(this.getUrl(id));
  }

}
