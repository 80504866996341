/*
 * File: breadcumbs.component.ts                                               *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st June 2022 15:25:56                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Breadcrumb } from 'src/app/catalog-cloud/interfaces/menus/breadcrumb.interface';
import { BreadcrumbService } from 'src/app/catalog-cloud/services/menus/breadcrumb.service';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { FascicleService } from 'src/app/catalog-cloud/services/fascicle/fascicle.service';
import { Fascicle } from 'src/app/catalog-cloud/interfaces/fascicle/fascicle.interface';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit,OnDestroy {
  menuItems: Breadcrumb[] = [];
  breadcrumbs$: Observable<Breadcrumb[]>;
  fascicles$: Subscription;

  catalogName: string;
  catalogId: string;
  fascicles: any;
  position: string;
  cuerrentFascicle;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private fascicleService: FascicleService
  ) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }
  ngOnDestroy(): void {
    this.fascicles$.unsubscribe();
  }

  ngOnInit(): void {
    this.suscriptions();
    this.catalogId = this.breadcrumbService.getRoot().id;
    this.catalogName = this.breadcrumbService.getRoot().name;
    this.cuerrentFascicle = this.breadcrumbService.getFascicles().currentId;
    this.fascicles = this.breadcrumbService.getFascicles().fascicles;
    this.position = this.breadcrumbService.getPosition();

  }

  suscriptions() {
    this.fascicles$ = this.fascicleService.dataFascicles.subscribe(
      (fasciculos: Fascicle[]) => {
        this.fascicles = fasciculos;
        console.log(this.cuerrentFascicle)
        this.cuerrentFascicle = this.breadcrumbService.getFascicles().currentId;
      }
    );
  }

  goToFascile(id: string) {
    if (id == 'CREATE') {
      this.fascicleService.newFascicle(this.catalogId);
    } else {
      this.router
        .navigate(['/catalog/' + this.catalogId + '/fascicle/' + id])
        .then(() => {
          window.location.reload();
        });
    }
  }
}
