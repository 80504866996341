
  <div class="product-list">
    <div
      class="product-list-item-container"
      *ngFor="let item of items"
      (cdkDropListDropped)="test($event)"
      cdkDropList
    >
      <app-product-list-item [item]="item"></app-product-list-item>
    </div>
  </div>
