<!--
 * File: cc-grid-selector.component.html                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 4th May 2022 15:43:50                              *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 31st May 2022 14:32:56                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->



<div fxLayout="row" fxLayoutAlign="space-between center" class="grid-selector-row">

    <button mat-icon-button (click)="toggle()">
      <mat-icon>{{ lockedIcon }}</mat-icon>
    </button>
    <!-- <button *ngIf="!lock" mat-icon-button (click)="cancel()">
      <mat-icon>undo</mat-icon>
    </button> -->
      <mat-select [disabled]="lock" [value]="this.selectedGrid.id" (selectionChange)="changeFormat($event)" class="grid-selector">
        <mat-option [value]="grid['_id']" *ngFor="let grid of grids">
          {{ grid.name }}
        </mat-option>
      </mat-select>

    <button mat-icon-button (click)="removePage(pageId)" [disabled]="!lock">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>

