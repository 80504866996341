/*
 * File: product-list.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 12th January 2022 17:06:58                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 23rd March 2022 17:07:06                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/catalog-cloud/interfaces/product/product.interface';
import { ApiService } from 'src/app/catalog-cloud/services/api.service';
import { ProductService } from 'src/app/catalog-cloud/services/product/product.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit , OnDestroy {


  subs = new Subscription();
  @Input() items: Product[] = [];
  loading:boolean;


  constructor(private api:ApiService,
    private productService:ProductService) {}

  ngOnInit(): void {
  }

  test(e: any) {
    console.log("Dropped!");
    console.log(e);
  }

  ondrag() {
    console.log('On drag!');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
