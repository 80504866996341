<!--
File: product-toolbar.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Thursday, 10th March 2022 12:08:22
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<!--
File: product-toolbar.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Sunday, 6th March 2022 22:40:48
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<!-- <div  class="product-toolbar mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch"> -->

<div
  class="product-toolbar"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <app-product-toolbar-search
    [viewType]="viewType"
    (viewTypeChange)="changeViewType($event)"
    [idCatalog]="idCatalog"
    [idFascicle]="idFascicle"
    [filter]="filter"
    [(loading)]="loading"
    (productList)="refreshList($event)"
  ></app-product-toolbar-search>
  <!-- <app-cg-loader [corner]="true"></app-cg-loader> -->
  <ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchCase="'list'">
      <app-product-list
        class="product-toolbar-list"
        [items]="filteredProducts"
      ></app-product-list>
    </ng-container>

    <ng-container *ngSwitchCase="'grid'">
      <app-product-grid
        class="product-toolbar-grid"
        [items]="filteredProducts"
      ></app-product-grid>
    </ng-container>
  </ng-container>

  <app-product-toolbar-product-details
    id="product-toolbar-details"
    class="product-toolbar-details"
  ></app-product-toolbar-product-details>
</div>
