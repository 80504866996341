<!--
File: fascicle-ask-unblock.component.html
Project: catalog-cloud
File Created: Tuesday, 1st February 2022 22:58:54
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:21:37
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<p>{{ 'FASCICLE.USER_WANTS_UNBLOCK_FASCICLE' | translate:{'username':claimantName,'fascicleName':fascicleName,'catalogName':catalogName} }} </p>

