/*
 * File: product-toolbar-product-details.component.ts                          *
 * Project: catalog-cloud                                                      *
 * File Created: Monday, 7th March 2022 12:47:46                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 2nd June 2022 18:19:47                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/catalog-cloud/interfaces/product/product.interface';
import { ProductService } from 'src/app/catalog-cloud/services/product/product.service';

@Component({
  selector: 'app-product-toolbar-product-details',
  templateUrl: './product-toolbar-product-details.component.html',
  styleUrls: ['./product-toolbar-product-details.component.scss']
})
export class ProductToolbarProductDetailsComponent implements OnInit,OnDestroy {
  item:Product;
  imShow:boolean = false;
  selectedProduct:Subscription;
  imShowSubscription:Subscription;

  constructor(private productService:ProductService) { }
  ngOnDestroy(): void {
    this.selectedProduct.unsubscribe();
    // this.imShowSubscription.unsubscribe();
  }


  ngOnInit(): void {
    this.selectedProduct = this.productService.viewDetails.subscribe((product:Product)=>{
      this.item = product;
    })
  }

  toogle(){
    this.imShow = this.imShow ? false:true;
    if (this.imShow){
      document.getElementById('showInfo').classList.add("btn-hide-info");
      document.getElementById('product-info-title').classList.add("reduce-header-margin");
      document.getElementById('product-toolbar-details').classList.add("product-toolbar-details-expanded");
    }
    else {
      document.getElementById('showInfo').classList.remove("btn-hide-info");
      document.getElementById('product-info-title').classList.remove("reduce-header-margin");
      document.getElementById('product-toolbar-details').classList.remove("product-toolbar-details-expanded");
    }
  }

  openProductForm(e:any)
  {
    // console.log(e);
    this.productService.setProductForm(e);
  }


}
