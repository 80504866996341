<!--
File: app.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 15:39:52
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


    <router-outlet></router-outlet>

