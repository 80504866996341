/*
 * File: fascicle-ask-unblock.component.ts                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 25th March 2022 10:57:49                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CatalogService } from 'src/app/catalog-cloud/services/catalog/catalog.service';
import { FascicleService } from 'src/app/catalog-cloud/services/fascicle/fascicle.service';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';

@Component({
  selector: 'app-fascicle-ask-unblock',
  templateUrl: './fascicle-ask-unblock.component.html',
  styleUrls: ['./fascicle-ask-unblock.component.scss'],
})
export class FascicleAskUnblockComponent implements OnInit {
  @Input() fascicleData: any;

  catalogId: string;
  catalogName: string;
  fascicleId: string;
  fascicleName: string;
  claimantId: string;
  blockedBy: string;
  claimantName: string;



  constructor(
    private userService: UserService,
    private catalogService: CatalogService,
    private fascicleService: FascicleService
  ) {}

  ngOnInit(): void {
    console.log(this.fascicleData);
    this.fascicleId = this.fascicleData.fascicleId;
    this.catalogId = this.fascicleData.catalogId;
    this.claimantId = this.fascicleData.claimantId;
    if (this.fascicleData) {
      this.catalogService.getCatalog(this.catalogId).subscribe((data) => {
        this.catalogName = data.name;
        this.fascicleName = data.fascicles.filter(
          (f) => f.id === this.fascicleId
        )[0].name;
        this.userService.getUserById(this.claimantId).subscribe((user) => {
          this.claimantName = user.name + ' ' + user.surname;

        });
      });
    }
  }


}
