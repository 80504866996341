<!--
File: breadcumbs.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Wednesday, 9th March 2022 17:25:33
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


<div class="breadcumb" *ngIf="((catalogName) && (position != 'home'))" >
  <span class="breadcumb-bread">
    <a href="/">{{ 'CATALOGS' | translate}}
    </a>
  </span>
  <span class="breadcumb-separator">/</span>
  <span class="breadcumb-bread">
    <a href="/catalog/{{catalogId}}/fascicles">{{catalogName}}</a>

  </span>

  <span class="breadcumb-separator">/</span>

    <mat-select [(value)]="cuerrentFascicle"  class="breadcrumb-select" (selectionChange)="goToFascile($event.value)">
      <mat-option *ngFor="let fascicle of fascicles" [value]="fascicle.id">{{fascicle.name}}</mat-option>
      <mat-option [value]="'CREATE'"><mat-icon>add</mat-icon> {{'FASCICLE.CREATE' |translate }}</mat-option>
    </mat-select>

</div>

<div class="breadcumb" *ngIf="((!catalogName) && (position === 'home'))">
  <!-- <span class="breadcumb-bread">
    <a href="/">{{ 'CATALOGS' | translate}}
    </a>
  </span> -->
</div>

<div class="breadcumb" *ngIf="((!catalogName) && (position === 'page-templates'))">
  <span class="breadcumb-bread">
    <a href="/">{{ 'CATALOGS' | translate}}
    </a>
  </span>
</div>
<div class="breadcumb" *ngIf="((!catalogName) && (position === 'users'))">
  <span class="breadcumb-bread">
    <a href="/">{{ 'CATALOGS' | translate}}
    </a>
  </span>
</div>
<div class="breadcumb" *ngIf="((!catalogName) && (position === 'Backups'))">
  <span class="breadcumb-bread">
    <a href="/">{{ 'CATALOGS' | translate}}
    </a>
  </span>
</div>



<!-- <div *ngFor="let item of (breadcrumbs$ | async); let last = last" class="breadcumb">

  <span [ngClass]="last ? 'breadcumb-last':'breadcumb-bread'" >
    <a *ngIf="item.link" href="{{item.link}}">{{item.title}}</a>
    <span *ngIf="!item.link ">{{item.title}}</span>
  </span>
  <span *ngIf="!last" class="breadcumb-separator"> / </span>
</div> -->


