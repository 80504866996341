<!--
File: product-grid-item.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 00:08:02
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div
  class="product-grid-item {{elevation}}"
  cdkDrag
  [cdkDragData]="item"
  (click)="select(item)"
  [class.selected]="selected"
  (mouseover)="addElevation()"
  (mouseout)="removeElevation()"
>
<div class="drag-preview-img-product mat-elevation-z8" *cdkDragPreview>
  <img

  [src]="item.img == null ? './assets/img/nube_cc_200x140.png' : item.img"
/>
</div>

  <div class="product-grid-item-header" [id]="'product-' + item.id">
    <img
      class="img-product"
      [src]="item.img == null ? './assets/img/nube_cc_200x140.png' : item.img"
    />
  </div>
  <div class="product-grid-item-content">
    <span class="product-title">{{ item.name }}</span>
    <span class="product-sku">Sku: {{ item.sku }}</span>
  </div>
</div>

