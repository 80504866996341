<span class="list-delete-header"> {{ (this.idsCatalog.length > 1 ? 'CATALOG.DELETE_MANY' : 'CATALOG.DELETE_ONE') | translate }}</span>

<!-- <mat-list >
  <mat-list-item *ngFor="let item of catalogsName">{{item}}</mat-list-item>
</mat-list> -->
<div class="list-delete-container">
  <span *ngFor="let item of catalogsName" class="list-delete-catalgos">
    {{item}}
  </span>
</div>



<!--
File: catalog-delete.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 16:45:10
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

