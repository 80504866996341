<!--
File: fascicle-edit.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:22:59
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<cc-top-menu context="fascicle"></cc-top-menu>
<div fxLayout="row" fxLayoutAlign="space-around stretch" class="fascicle-desk">
  <!-- Cuando si existen páginas -->
  <div
    fxLayout="row"
    fxFlex="100"
    fxLayoutAlign="space-between stretch"
    cdkDropListGroup
    class="wrap-fascicle"
    *ngIf="pages.length > 0"
  >
    <!-- Show pages -->
    <div
      class="wrap-content"
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxFlex="80"
    >
      <app-cg-loader *ngIf="loading"></app-cg-loader>

      <div class="page-wrap">
        <div
          class="grid-container left"
          *ngIf="currentPageIndex.length < 2 && pageIsOdd(currentPageIndex[0])"
        ></div>

        <div
          class="grid-container mat-elevation-z2"
          [ngClass]="
            !gridLocked.locked && pageIndex != gridLocked.index
              ? 'disable-toolbar'
              : ''
          "
          [ngClass]="
            pageIsOdd(pageIndex)
              ? 'grid-container-right'
              : 'grid-container-left'
          "
          *ngFor="let pageIndex of currentPageIndex; index as i"
        >
          <button *ngIf="!pageIsOdd(pageIndex)" class="button-page"
          (click)="gPage.previous()"
              [disabled]="gPage.previousIsDisabled()"
          >

              <mat-icon class="page-icon">navigate_before</mat-icon>
          </button>
          <app-cc-grid
            *ngIf="pages[pageIndex]"
            [pageIndex]="pageIndex"
            [totalPages]="pages.length"
            [baseGrids]="allPageTemplates"
            [idCatalog]="idCatalog"
            [idFascicle]="idFascicle"
            [idPage]="pages[pageIndex].id"
            [isOdd]="pageIsOdd(pageIndex)"
            (isLocked)="checkLocked($event)"
          >
          </app-cc-grid>
          <button *ngIf="pageIsOdd(pageIndex)" class="button-page"
          (click)="gPage.next()"
          [disabled]="gPage.nextIsDisabled()">
            <mat-icon class="page-icon">navigate_next</mat-icon>
          </button>
        </div>

        <div
          class="grid-container right"
          *ngIf="currentPageIndex.length < 2 && !pageIsOdd(currentPageIndex[0])"
        ></div>
      </div>

      <!-- Paginador -- ¡Se merece un componente! -->
      <div class="page-paginator" fxLayout="row" fxLayoutAlign="center center">

        <!-- First Page -->
        <button mat-icon-button (click)="gPage.goToFirst()">
          <mat-icon>first_page</mat-icon>
        </button>
        <!-- Previous Page -->
        <button
          mat-icon-button
          (click)="gPage.previous()"
          [disabled]="gPage.previousIsDisabled()"
        >
          <mat-icon>navigate_before</mat-icon>
        </button>
        <!-- Page Selector -->
        <mat-select
          [value]="gPage.index"
          (valueChange)="gPage.goToIndex($event)"
          class="select-pages"
        >
          <mat-option
            *ngFor="let pagination of gPage.combo"
            [value]="pagination.key"
            >{{ pagination.value }}</mat-option
          >
        </mat-select>
        <!-- Next Page -->
        <button
          mat-icon-button
          (click)="gPage.next()"
          [disabled]="gPage.nextIsDisabled()"
        >
          <mat-icon>navigate_next</mat-icon>
        </button>
        <!-- Last Page -->
        <button mat-icon-button (click)="gPage.goToLast()">
          <mat-icon>last_page</mat-icon>
        </button>
        <!-- Add Page -->
        <button mat-icon-button (click)="addPage()">
          <mat-icon>add</mat-icon>
        </button>
        <!-- Add Page Menú **Pensar en sacarlo a un componente** **Todo el paginador**-->

        <button mat-icon-button
          [matMenuTriggerFor]="addPageMenu"
          #buttonMenuPages="matMenuTrigger"
          >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #addPageMenu>
          <div
            style="
              text-align: center;
              border-bottom: 1px solid #ccc;
              width: 100%;
              padding-bottom: 0.5em;
            "
          >
            {{ "PAGE.ADDPAGE" | translate }}
          </div>
          <div mat-menu-item (click)="addPageFirst()"><mat-icon>first_page</mat-icon>{{ "PAGE.ADDTOFIRST" | translate }}</div>
          <div mat-menu-item (click)="addPage()"><mat-icon>last_page</mat-icon> {{ "PAGE.ADDTOLAST" | translate }}</div>


          <div (click)="$event.stopPropagation();$event.preventDefault">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'PAGE.ADDBEFORE' | translate }}</mat-label>
              <mat-select #selectPageBefore (selectionChange)="addPageBefore($event.value,buttonMenuPages)">
                <mat-option *ngFor="let number of currentNumberPages" [value]="number">{{number}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div (click)="$event.stopPropagation();$event.preventDefault">
            <mat-form-field  appearance="fill" color="primary">
              <mat-label>{{ 'PAGE.ADDNEXTTO' | translate }}</mat-label>
              <mat-select #selectPageAfter (selectionChange)="addPageAfter($event.value,buttonMenuPages)">
                <mat-option *ngFor="let number of currentNumberPages" [value]="number">{{number}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-menu>
      </div>
    </div>

    <!-- Show Toolbar -->
    <app-product-toolbar
      [ngClass]="!gridLocked.locked ? 'disable-toolbar' : ''"
      *ngIf="!loading"
      fxFlex="20"
      [idCatalog]="idCatalog"
      [idFascicle]="idFascicle"
      [filter]="productFilter"
    ></app-product-toolbar>
  </div>

  <!-- Si no existen páginas  -->
  <div
    fxLayout="row"
    fxFill
    fxLayoutAlign="center center"
    cdkDropListGroup
    class="wrap-fascicle"
    *ngIf="pages.length == 0"
  >
    <mat-card>
      <mat-card-content>
        <span>{{ "FASCICLE.NO_PAGES" | translate }} </span>
      </mat-card-content>
      <mat-card-actions>
        <span>
          <button mat-raised-button color="primary" (click)="addTheFirstPage()">
            {{ "FASCICLE.CREATE_PAGE" | translate }}
          </button>
        </span>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
