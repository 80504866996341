<!--
File: fascicle-list.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:33:04
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<app-cg-loader [corner]="true" *ngIf="loading"></app-cg-loader>
<div class="table-fascicles-container">
  <mat-table
    #fasTable
    [dataSource]="dataSource"
    *ngIf="dataLoaded == true"
    cdkDropList
    cdkDropListData="dataSource"
    (cdkDropListDropped)="changeFascicleOrder($event)"
    [cdkDropListDisabled]="loading == true"
    [class.loading]="loading == true"
  >
    <ng-container matColumnDef="moveRow">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let fascicle">
        <mat-icon
          id="move-{{ fascicle.id }}"
          cdkDragHandle
          cdkDragLockAxis="y"
          class="move-fascicle move-fascicle-not-visible"
          >drag_indicator</mat-icon
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="isBlocked">
      <mat-header-cell *matHeaderCellDef
        >{{ "STATUS" | translate | uppercase }}
      </mat-header-cell>

      <mat-cell *matCellDef="let fascicle"> </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef
        >{{ "NAME" | translate | uppercase }}
      </mat-header-cell>

      <mat-cell *matCellDef="let fascicle">
        <a
          class="fascicle-title"
          routerLink="catalog/{{ idCatalog }}/fascicle/{{ fascicle.id }}"
          >{{ fascicle.name }}</a
        >
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="author">
      <mat-header-cell *matHeaderCellDef
        >{{ "CREATED_BY" | translate | uppercase }}
      </mat-header-cell>

      <mat-cell *matCellDef="let fascicle">
        {{ fascicle.createdBy.name }}  {{ fascicle.createdBy.surname |slice:0:1}}.
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="pages">
      <mat-header-cell *matHeaderCellDef
        >{{ "PAGES" | translate | uppercase }}
      </mat-header-cell>

      <mat-cell *matCellDef="let fascicle">{{ fascicle.fromto }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="updatedAt">
      <mat-header-cell *matHeaderCellDef
        >{{ "UPDATED_AT" | translate | uppercase }}
      </mat-header-cell>
      <mat-cell *matCellDef="let fascicle">
        <span>
          {{ fascicle.updatedAt | date: "dd MMM YYYY HH:mm" }}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef
        >{{ "ACTIONS" | translate | uppercase }}
      </mat-header-cell>

      <mat-cell *matCellDef="let fascicle">
        <button
          mat-icon-button
          *ngIf="fascicle.blocked && fascicle.blockedBy.id != user.id"
          (click)="requestUnBlock(fascicle)"
          matToo
        >
          <mat-icon
            color="primary"
            class="material-icons-outlined"
            matTooltip="{{ 'FASCICLE.BLOCKED_BY' | translate }}  {{
              fascicle.blockedBy.name
            }} {{ fascicle.blockedBy.surname }}"
            matTooltipClass="tooltip-warn"
            matTooltipPosition="right"
          >
            lock
          </mat-icon>
        </button>

        <!-- <mat-icon
          color="primary"
          class="material-icons-outlined"
          *ngIf="!fascicle.blocked || fascicle.blockedBy.id == user.id"
        >
          lock_open
        </mat-icon> -->
        <span *ngIf="!fascicle.blocked || fascicle.blockedBy.id == user.id">
          <div id="actions-row-{{ fascicle.id }}" class="actions-not-visible">
            <a
              mat-icon-button
              routerLink="catalog/{{ idCatalog }}/fascicle/{{ fascicle.id }}"
              matTooltip="{{ 'FASCICLE.EDIT' | translate }}"
            >
              <mat-icon class="material-icons-outlined">edit_note</mat-icon>
            </a>
            <button mat-icon-button (click)="openFascicleSettings(fascicle.id)"  matTooltip="{{ 'FASCICLE.EDIT_PROPERTIES' | translate }}">
              <mat-icon class="material-icons-outlined">tune</mat-icon>
            </button>
            <button mat-icon-button (click)="clone(fascicle.id)"  matTooltip="{{ 'FASCICLE.CLONE' | translate }}">
              <mat-icon class="material-icons-outlined">content_copy</mat-icon>
            </button>
            <button mat-icon-button (click)="delete(fascicle.id, idCatalog)"  matTooltip="{{ 'FASCICLE.REMOVE' | translate }}">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
          </div>
        </span>
        <span *ngIf="fascicle.blocked && fascicle.blockedBy.id != user.id">
          {{ "FASCICLE.BLOCKED_BY" | translate }} {{ fascicle.blockedBy.name }}
          {{ fascicle.blockedBy.surname }}
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->

    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.mat-elevation-z9]="row.isHover"
      [class.row-hover]="row.isHover"
      [class.row-no-hover]="!row.isHover"
      class="remove-background"
      (mouseover)="handleMouseOver(row)"
      (mouseleave)="handleMouseLeave(row)"
      cdkDrag
      cdkDragLockAxis="y"
      [cdkDragData]="row"
    ></mat-row>
  </mat-table>
</div>
