import { CcGrid } from './cc-grid.model';
import { CcGridCell } from './cc-grid-cell.model';
export interface cells {
  cell: CcGridCell;
  cLeft: CcGridCell;
  cRight: CcGridCell;
  cUp: CcGridCell;
  cBottom: CcGridCell;
}

export class CcGridCellButton {

  visible: boolean;

  constructor(
    private cellId: number,
    private grid: CcGrid,
    private side: string
  ) {}

  initArroundCells(cellId: number): cells {
    return {
      cell: this.grid.cells[cellId],
      cLeft: this.grid.cells[cellId].cellLeft(),
      cRight: this.grid.cells[cellId].cellRight(),
      cUp: this.grid.cells[cellId].cellUp(),
      cBottom: this.grid.cells[cellId].cellDown(),
    };
  }

  showIncrease() {
    switch (this.side) {
      case 'right': {
        this.visible = this.showIncreaseRight();
        break;
      }
      case 'left': {
        this.visible = this.showIncreaseLeft();
        break;
      }
      case 'top': {
        this.visible = this.showIncreaseTop();
        break;
      }
      case 'bottom': {
        this.visible = this.showIncreaseBottom();
        break;
      }
    }

    return this.visible;
  }

  showDrecrease() {
    let cells = this.initArroundCells(this.cellId);
    if (!cells.cell.isExpanded()) return false;
    switch (this.side) {
      case 'right': {
        this.visible = this.showDecreaseRight();
        break;
      }
      case 'left': {
        this.visible = this.showDecreaseLeft();
        break;
      }
      case 'top': {
        this.visible = this.showDecreaseTop();
        break;
      }
      case 'bottom': {
        this.visible = this.showDecreaseBottom();
        break;
      }
    }
    return this.visible;
  }

  showButton() {
    return this.showDrecrease() == true || this.showIncrease() == true;
  }

  /**
   * Casos en los que se ve el boton izquierdo
   * No está en la primera columna
   * La(s) celdas contiguas a la izquierda no está(n) combinadas
   * La(s) celdas contiguas a la izquierda no está(n) ocultas
   *
   * @returns boolean
   */
  showIncreaseLeft(): boolean {
    let cells = this.initArroundCells(this.cellId);
    let rows = cells.cell.data.rowWidth;

    if (cells.cell.isFirstOfRow()) return false;
    if (cells.cLeft.parentCell != null) {
      let parentCell = cells.cLeft.parentCell;
      if (parentCell.rowPosition != cells.cell.data.rowPosition) return false;
      if (parentCell.rowWidth != cells.cell.data.rowWidth) return false;
    } else if (
      cells.cLeft.isVerticalExpanded() &&
      cells.cell.data.rowWidth != cells.cLeft.data.rowWidth
    )
      return false;

    return true;
  }

  showDecreaseLeft(): boolean {
    let cells = this.initArroundCells(this.cellId);

    return cells.cell.isHorizontalExpanded();
  }

  /**
   * Casos en los que se ve el boton derecho
   * No está en la última columna
   * La(s) celdas contiguas a la derecha no está(n) combinadas
   * La(s) celdas contiguas a la derecha no está(n) ocultas
   * Tener en cuenta que hay que saber si esta expandida y cuanto
   * @returns boolean
   */
  showIncreaseRight(): boolean {
    let cells = this.initArroundCells(this.cellId);
    let expansion = cells.cell.data.columnWith;
    let nextCell = cells.cell.goToCell(this.cellId + expansion);

    if (cells.cell.isLastOfRow()) return false;

    if (
      nextCell.isVerticalExpanded() &&
      cells.cell.data.rowWidth != nextCell.data.rowWidth
    )
      return false;

    return true;
  }

  showDecreaseRight(): boolean {
    let cells = this.initArroundCells(this.cellId);

    return cells.cell.isHorizontalExpanded();
  }

  showIncreaseTop(): boolean {
    let cells = this.initArroundCells(this.cellId);

    if (cells.cell.isOnTop()) return false;

    if (cells.cUp.parentCell != null)
    {
      let parentcell = cells.cUp.parentCell;
      if (parentcell.columnPosition != cells.cell.data.columnPosition) return false;
      if (parentcell.columnWith != cells.cell.data.columnWith) return false;

    }


    return true;
  }

  showDecreaseTop(): boolean {
    let cells = this.initArroundCells(this.cellId);
    return cells.cell.isVerticalExpanded();
  }

  showIncreaseBottom(): boolean {
    let cells = this.initArroundCells(this.cellId);

    if (cells.cell.isOnBottom()) return false;

    if (cells.cell.isVerticalExpanded())
    {
      let nextBottomVisible = this.cellId + (this.grid.columns * cells.cell.data.rowWidth);
      if (nextBottomVisible > (this.grid.totalCells)) return false;
      let cellDown = this.grid.cells[nextBottomVisible];
      if (cellDown.parentCell != null) return false;
      if (cellDown.data.columnWith > cells.cell.data.columnWith) return false;
    }

    return true;
  }

  showDecreaseBottom(): boolean {
    let cells = this.initArroundCells(this.cellId);

    return cells.cell.isVerticalExpanded();
  }
}
