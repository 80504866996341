/*
 * File: top-menu.component.ts                                                 *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 17th June 2022 11:06:19                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { IUser } from 'src/app/core/interfaces/user.interface';

@Component({
  selector: 'cc-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  user: IUser;
  inconMenu = 'menu';
  sidenavIsExpanded:boolean = false;
  @Input() context:string;

  constructor(private userService:UserService) {
  }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.listenForExpanstion();
  }


  listenForExpanstion(){
 //   this.sidenavService.isExpanded.subscribe(expansion => this.sidenavIsExpanded = expansion);
  }


}
