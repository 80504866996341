<!--
File: catalog-sync-differences.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:12:35
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->
<div fxLayout="column" fxLayoutAlign="space-between stretch">
  <div
    class="test-connection"
    fxLayout="column"
    fxLayoutAlign="space-between center"
    *ngIf="!loading && !started"
  >
    <button mat-raised-button color="primary" (click)="testConnection()">
      <!-- <mat-icon>cloud_download</mat-icon>  {{ "SYNC" | translate }} -->
      {{ "SYNC" | translate }}
    </button>
  </div>

  <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>

  <div fxLayout="row" fxLayoutAlign="center center" class="sync-info">
    <div>
      <mat-icon>{{ testConnectionIcon }}</mat-icon>
    </div>
    <div>
      <span>{{ testConnectionMessage }}</span>
    </div>
  </div>

  <table *ngIf="!loading && connectionStatus" class="diff-table">
    <thead>
      <tr>
        <th></th>
        <th>{{ "PRODUCTS" | translate }}</th>
        <th>{{ "CATEGORIES" | translate }}</th>
        <th>{{ "TAGS" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>{{ "NEWS" | translate }}</th>
        <td>{{ infoDifferences.products.added }}</td>
        <td>{{ infoDifferences.categories.added }}</td>
        <td>{{ infoDifferences.tags.added }}</td>
      </tr>
      <tr>
        <th>{{ "UPDATES" | translate }}</th>
        <td>{{ infoDifferences.products.updated }}</td>
        <td>{{ infoDifferences.categories.updated }}</td>
        <td>{{ infoDifferences.tags.updated }}</td>
      </tr>
      <tr>
        <th>{{ "DELETES" | translate }}</th>
        <td>{{ infoDifferences.products.removed }}</td>
        <td>{{ infoDifferences.categories.removed }}</td>
        <td>{{ infoDifferences.tags.removed }}</td>
      </tr>
    </tbody>
  </table>
</div>

<!-- <div
  *ngIf="result"
  fxFill
  fxLayout="row"
  fxLayoutAlign="space-around center"
  class="results"
>
  <span>{{ 'SYNC_OK' | translate }} </span>
</div> -->
