<!--
File: form-generator.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Thursday, 10th March 2022 23:41:07
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<ng-container [ngSwitch]="field.type" *ngIf="field" >
  <!-- Hidden Field -->
  <input
    *ngSwitchCase="'hidden'"
    type="hidden"
    formControlName="{{ field.key }}"
    id="{{ field.key }}"
  />

  <!-- Input normal Text -->
  <mat-form-field
    appearance="outline"
    *ngSwitchCase="'text'"
    class="full-witdth"


  >
    <mat-label>{{ field.label | translate }}</mat-label>
    <input
      matInput
      type="text"
      formControlName="{{ field.key }}"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="none"
      spellcheck="false"

    />
    <mat-icon *ngIf="formGroup.controls[field.key].errors" matSuffix class="field-error">error</mat-icon>
    <mat-error *ngIf="formGroup.controls[field.key].errors" class="field-error">
      <div *ngIf="formGroup.controls[field.key].errors.message;else anonError" >{{formGroup.controls[field.key].errors.message}}</div>
      <ng-template #anonError>{{ 'INVALID_INPUT' | translate }} </ng-template>
     </mat-error>

  </mat-form-field>

  <!-- Input Password Type -->
  <mat-form-field
    appearance="outline"
    *ngSwitchCase="'password'"
    class="full-witdth"
  >
    <mat-label>{{ field.label | translate}}</mat-label>
    <input
      matInput
      [type]="visible ? 'text' : 'password'"
      formControlName="{{ field.key }}"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="none"
      spellcheck="false"
    />
    <mat-icon
      matSuffix
      style="cursor: pointer"
      (mousedown)="viewIt()"
      (mouseup)="hideIt()"
      >{{ visible ? "visibility" : "visibility_off" }}</mat-icon
    >
    <mat-error *ngIf="formGroup.controls[field.key].errors" class="field-error">
      <div *ngIf="formGroup.controls[field.key].errors.message;else anonError" >{{formGroup.controls[field.key].errors.message | translate }}</div>
      <ng-template #anonError>{{ 'INVALID_INPUT' | translate }}</ng-template>
     </mat-error>
  </mat-form-field>

  <!-- Autocomplete Customized Type -->
  <app-cc-autocomplete
  *ngSwitchCase="'autoComplete'"
    [appeareance]="'outline'"
    [flexSize]="90"
    [name]="field.key"
    [fg]="formGroup"
    [label]="field.label | translate"
    [data]="dataAutoComplete[field.data['base']]"
    [dataSelected]="dataAutoComplete[field.data['selected']]"
    [mainField]="field.key"
    (selected)="returnData($event)"
    (removed)="returnRemovedData($event)"
    [chips]="dataAutoComplete[field.data['selected']]"
  >
  </app-cc-autocomplete>


  <mat-form-field
    appearance="outline"
    *ngSwitchCase="'select'"
    class="full-witdth"
  >

  <mat-label>{{field.label |translate}}</mat-label>

  <mat-select formControlName="{{ field.key }}" [(value)]="selectedValue">
    <mat-option *ngFor="let item of field.data['base']" [value]="item.value">{{item.label | translate}}</mat-option>
  </mat-select>
  </mat-form-field>
</ng-container>

