import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cg-loader',
  templateUrl: './cg-loader.component.html',
  styleUrls: ['./cg-loader.component.scss']
})
export class CgLoaderComponent implements OnInit {

  @Input() corner:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
