/*
 * File: fascicle.resolver.ts                                                  *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 9th March 2022 14:41:17                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 13th October 2022 10:33:38                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { CatalogService } from '../services/catalog/catalog.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogResolver implements Resolve<any> {
  constructor (private catalogService:CatalogService){}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    var idCat =  route.paramMap.get("idCatalog");
    // console.log( route.paramMap)
    if (idCat){
      let catalogResolved =  await this.catalogService.getBreadCrumb(idCat);
        // console.log("Catalogo Resuelto",catalogResolved);
      return catalogResolved;
    }
    else {
      return null;
    }

  }
}
