
<!--
File: product-details.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:47:56
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


<div
class="item-details mat-elevation-z3 item-details-hide"
id="product-details-{{ item.id }}"
(mouseleave)="hide()"
>
<ul class="product-datails">
  <li><strong>{{ 'PRODUCT.SKU' | translate }}: </strong> {{ item.sku }}</li>
  <li><strong>{{ 'NAME' | translate }}:</strong> {{ item.name }}</li>
  <li><strong>{{ 'DESCRIPTION' | translate }}:</strong> {{ item.description }}</li>
  <li><strong>{{ 'TAGS' | translate }}:</strong>{{ item.tags.toString() }}</li>
  <li><strong>{{ 'PRODUCT.FAMILY' | translate }}: </strong> {{ item.family }}</li>
  <li *ngFor="let extra of item.extra">
    <strong>{{ extra.label }}: </strong> {{ extra.value }}
  </li>
  <li>
    <strong>{{ 'CREATED_AT' | translate }} </strong>
    {{ item.createdAt | date: "dd/MM/yyyy - HH:mm" }}
  </li>
</ul>
</div>



