/*
 * File: product-toolbar-search.component.ts                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Sunday, 6th March 2022 22:46:11                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 15th July 2022 14:30:51                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Product } from 'src/app/catalog-cloud/interfaces/product/product.interface';
import { PageService } from 'src/app/catalog-cloud/services/page/page.service';
import { ProductService } from 'src/app/catalog-cloud/services/product/product.service';
import { keyValue } from 'src/app/core/interfaces/key-value';
import { PopInfoService } from 'src/app/core/services/pop-info-service.service';

@Component({
  selector: 'app-product-toolbar-search',
  templateUrl: './product-toolbar-search.component.html',
  styleUrls: ['./product-toolbar-search.component.scss'],
})
export class ProductToolbarSearchComponent implements OnInit, OnDestroy {
  @Input() idCatalog: string;
  @Input() idFascicle: string;
  @Input() filter: keyValue[];
  @Input() loading: boolean = true;
  @Input() viewType: string;

  @Output() productList: EventEmitter<Product[]> = new EventEmitter<
    Product[]
  >();
  @Output() loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() viewTypeChange: EventEmitter<string> = new EventEmitter<string>();

  showInsertedProducts: boolean = false;
  showProductDetails: boolean = false;
  showFilters: boolean = true;

  baseProducts:Product[] = []; //Loaded products from server
  products: Product[] = [];

  addedProducts: string[] = [];
  filteredProducts: Product[] = [];
  searchTerms: string[] = [];

  productOrder: string = 'asc';

  addProductsObserver: Subscription;
  delProductsObserver: Subscription;
  checkScroll: Subscription;

  pageRemoveObserver: Subscription;

  totalProducts: number = 0;

  offset: number = 0;
  searchType:string = 'AND';
  searchTypes = [
    {'name': this.trans.instant('SEARCH.AND_TYPE'),'value':'AND',"checked":true},
    {'name':this.trans.instant('SEARCH.OR_TYPE'),'value':'OR',"checked":false}
  ]

  constructor(
    private productService: ProductService,
    private pageService: PageService,
    private popup: PopInfoService,
    private trans: TranslateService
  ) {}

  ngOnInit(): void {
    this.setTotalProducts();

    // this.checkScroll = this.productService.searchScrollHandler.subscribe(
    //   (scrolled: boolean) => {
    //     this.loadProducts();
    //   }
    // );
  }

  ngOnDestroy(): void {
    this.addProductsObserver.unsubscribe();
    this.delProductsObserver.unsubscribe();
    // this.checkScroll.unsubscribe();
  }

  async setTotalProducts() {
    let categories = this.filter.filter((f) => f.id == 'categories')[0];
    let tags = this.filter.filter((f) => f.id == 'tags')[0];
    await this.productService
      .countProducts(this.idCatalog, categories.value, tags.value)
      .subscribe((data) => {
        this.totalProducts = data;
        this.initToolbarSearch();
      });
  }

  addFilter(e: any) {
    this.products = [];
    this.productService.offsetSearch = 0;
    if (e.value.trim().length > 0) {
      this.searchTerms.push(e.value);
      e.value = '';
      this.searchProducts();
    }
  }

  changeLoading(isLoading: boolean) {
    this.loading = isLoading;
    this.loadingChange.emit(this.loading);
  }

  changeViewType(type: any) {
    this.viewTypeChange.emit(type);
  }



  initToolbarSearch() {
    this.productService
      .getAddedProducts(this.idCatalog, this.idFascicle)
      .subscribe((addedProducts) => {
        this.addedProducts = addedProducts;
        this.filterProducts();
        this.changeLoading(false);
        this.setProductHandlers();
        this.loadProducts();
      });
  }

  removeSearchItem(searchTerm: string) {
    let index = this.searchTerms.indexOf(searchTerm);
    this.products = [];
    this.productService.offsetSearch = 0;
    this.searchTerms.splice(index, 1);
    this.searchProducts();
  }

  toogleShowFilter() {
    this.showFilters = this.showFilters ? false : true;
    let btn = document.getElementById('btn-show-filters');
    if (this.showFilters) btn.classList.add('btn-show-filters-expanded');
    else btn.classList.remove('btn-show-filters-expanded');
  }

  filterProducts() {
    if (!this.showInsertedProducts) {
      this.filteredProducts = this.products.filter(
        (prod) => !this.addedProducts.includes(prod.id)
      );
    } else {
      this.filteredProducts = this.products;
    }
    // let properties = this.productService.getAllProperties(this.filteredProducts);
    // console.log(properties);

    this.productList.emit(this.filteredProducts);
    this.productService.orderHandler.next(this.productOrder);
    this.changeLoading(false);
  }



  setProductHandlers() {

    this.addProductsObserver = this.productService.addProduct$.subscribe(
      (prod) => {
        this.addedProducts.push(prod);
        this.filterProducts();
      }
    );

    this.delProductsObserver = this.productService.removeProduct$.subscribe(
      (prod) => {
        let index = this.addedProducts.indexOf(prod);
        if (index >= 0) this.addedProducts.splice(index, 1);
        this.filterProducts();
      }
    );

    this.pageRemoveObserver = this.pageService.returnItemToToolbar$.subscribe(
      (prod) => {
        let index = this.addedProducts.indexOf(prod);
        if (index >= 0) this.addedProducts.splice(index, 1);
        this.filterProducts();
      }
    );
  }

  changeOrder(order: any) {
    this.productOrder = order;
       this.productService.orderHandler.next(order);
  }

  loadProducts(){
    this.productService
      .postSearchProducts(
        this.idCatalog,
        this.filter,
        this.searchTerms,
        this.productOrder
      )
      .subscribe(
        (dbProducts) => {
          this.baseProducts = dbProducts;
          this.products.push(...dbProducts);
          this.filterProducts();
          this.offset = this.productService.getOffset();
          if (this.offset > this.products.length)
            this.offset = this.products.length;
          this.productService.offsetSearchIncrement();
        },
        (err) => {
          this.popup.addMessage(
            'warn',
            err.toString(),
            'SEARCH_PRODUCT_ERROR'
          );
        }
      );
  }

  searchProducts() {
      this.changeLoading(true);
      if (this.searchType == 'AND')
      {
        this.products = this.productService.andFilter(this.baseProducts,this.searchTerms,null)
      }
      else{
        this.products = this.productService.orFilter(this.baseProducts,this.searchTerms,null)
      }

      this.filterProducts();

  }

  toggleShowInsertedProducts() {
    this.showInsertedProducts = this.showInsertedProducts ? false : true;
    this.filterProducts();
  }

  changeSearchType(e){
    console.log(e);
    this.searchType = e;
    this.searchProducts();

  }
}
