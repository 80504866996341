/*
 * File: form-generator.component.ts                                           *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 16th March 2022 12:32:31                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SkipSelf } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { keyValue } from '../../interfaces/key-value';
// import { AutocompleteInterface } from './interfaces/autocomplete.interface';
import { FieldInterface } from './interfaces/field.interface';

@Component({
  selector: 'app-form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new SkipSelf(), ControlContainer]],
  }]
})
export class FormGeneratorComponent implements OnInit,OnChanges {

  @Input() field:FieldInterface;
  @Input() dataAutoComplete?:any[];
  @Input() formGroup?:FormGroup;
  @Output() returnSelected:EventEmitter<any> = new EventEmitter();
  @Output() returnRemoved:EventEmitter<any> = new EventEmitter();
  visible:boolean = false;

  selectedValue:string;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
   if (this.field.type === 'select')
   {
    console.log(changes)

     if (this.field.data)
       {
         if (this.field.data.selected)
         {
           this.selectedValue = this.field.data.selected
         }
         else{
           this.selectedValue = '';
         }
       }

   }
  }

  ngOnInit(): void {



  }

  viewIt(){
    this.visible = true;
  }

  hideIt(){
    this.visible = false;
  }

  returnData(e:any){
    this.returnSelected.emit(e);
  }

  returnRemovedData(e:any){
    this.returnRemoved.emit(e);
  }

}
