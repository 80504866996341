/*
 * File: app.component.ts                                                      *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 13th October 2022 10:33:55                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  NavigationEnd,
  Router,
  Event as NavigationEvent,
} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './catalog-cloud/services/api.service';
import { ActionsMenuService } from './catalog-cloud/services/menus/actionsMenu.service';
import { FascicleSocketService } from './catalog-cloud/services/fascicle/fascicle-socket.service';
import { IUser } from './core/interfaces/user.interface';
import { MatIconSvg } from './core/registry/mat-icon-svg';
import { UserService } from './catalog-cloud/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { langs } from 'src/assets/i18n/available-lang';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', animate(500)),
    ]),
  ],
})
export class AppComponent {
  title = 'Catalog Cloud';
  user: IUser;
  router: Router;
  noLoggedRoute: Promise<string> = null;

  @ViewChild('sidenav') public sidenav: MatSidenav;

  currentRoute: string;

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private _router: Router,
    private matIconsvg: MatIconSvg,
    private userService: UserService,
    private fascicleSocketService: FascicleSocketService,
    public translate: TranslateService
  ) {
    this.user = userService.setUser();
    this.router = _router;
    fascicleSocketService.startSocket(this.user.id);

    // Register translation languages

    translate.addLangs(
      langs.map((l) => {
        return l.value;
      })
    );

    // console.log(this.user.lang)
    // Set default language
    if (this.user.lang && this.user.lang.length > 0)
      translate.setDefaultLang(this.user.lang);
    else translate.setDefaultLang('es_ES');
  }

  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }

  passUrl(navigation: string) {
    return Promise.resolve(navigation);
  }

}
