<!--
File: product-list-item.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Thursday, 10th March 2022 12:39:31
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<!-- <mat-card  cdkDrag [cdkDragData]="item" class="product-list-item" >
  <img class="img-product-drag mat-elevation-z7" src="{{item.img}}" [alt]="item.name" *cdkDragPreview />
  <mat-card-content [id]="item.id" class="product-content" >
    <img class="img-product" src="{{item.img}}" />
  </mat-card-content>
  <mat-card-footer class="product-footer">
    <span class="product-title">{{item.name}}</span>
    <span class="product-sku">{{item.description}}</span>
  </mat-card-footer>
</mat-card> -->

<div
  class="product-list-item {{elevation}}"
  fxLayout="row"
  cdkDrag
  [cdkDragData]="item"
  (click)="select(item)"
  [class.selected] ="selected"
  (mouseover)="addElevation()"
  (mouseout)="removeElevation()"

  >
  <!-- <img *cdkDragPreview src="{{ item.img }}" /> -->
  <div class="product-list-item-header">
    <img class="img-product-list-item"  [src]="item.img == null ? './assets/img/nube_cc_200x140.png' : item.img" />
  </div>
  <div class="product-list-item-content">
    <span class="product-title">{{ item.name }}</span>
    <span class="product-sku">Sku: {{ item.sku }}</span>
  </div>
</div>
