/*
 * File: app-routing.module.ts                                                 *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 20th September 2022 13:01:31                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CatalogFormComponent } from '../catalog-cloud/components/catalog/catalog-form/catalog-form.component';
import { CatalogHomeComponent } from '../catalog-cloud/components/catalog/catalog-home/catalog-home.component';
import { CatalogListComponent } from '../catalog-cloud/components/catalog/catalog-list/catalog-list.component';
import { FascicleEditComponent } from '../catalog-cloud/components/fascicle/fascicle-edit/fascicle-edit.component';
import { FascicleListAloneComponent } from '../catalog-cloud/components/fascicle/fascicle-list-alone/fascicle-list-alone.component';
import { PageTemplateFormComponent } from '../catalog-cloud/components/page/page-template-form/page-template-form.component';
import { PageTemplateListComponent } from '../catalog-cloud/components/page/page-template-list/page-template-list.component';
import { ListComponent } from '../catalog-cloud/components/user/list/list.component';
import { LoginComponent } from '../catalog-cloud/components/user/login/login.component';
import { ResetPasswordComponent } from '../catalog-cloud/components/user/reset-password/reset-password.component';
import { CatalogResolver } from '../catalog-cloud/resolvers/catalog.resolver';
import { FascicleResolver } from '../catalog-cloud/resolvers/fascicle.resolver';
import { BackupsListComponent } from '../catalog-cloud/components/backups/backups-list/backups-list.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginActivate } from './helpers/login-activate.helper';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'home',
    },
    component: CatalogHomeComponent,
    canActivate: [LoginActivate],
  },
  {
    path: 'catalog',
    canActivate: [LoginActivate],

    children: [
      {
        path: 'list',
        data: {
          breadcrumb: 'Listado de catálogos',
        },
        component: CatalogListComponent,
        canActivate: [LoginActivate],
      },
      {
        path: 'new',
        component: CatalogFormComponent,
        canActivate: [LoginActivate],
      },
      {
        path: ':id',
        component: CatalogFormComponent,
        canActivate: [LoginActivate],
      },
    ],
  },
  {
    path: 'catalog/:idCatalog/fascicles',
    canActivate: [LoginActivate],
    data: {
      breadcrumb: (data: any) => {
        data: data;
      },
    },
    resolve: {
      catalog: CatalogResolver,
    },
    component: FascicleListAloneComponent,
  },
  {
    path: 'page-templates',
    canActivate: [LoginActivate],
    data: {
      breadcrumb: 'page-templates',
    },
    children: [
      {
        path: 'list',
        component: PageTemplateListComponent,
        canActivate: [LoginActivate],
      },
      {
        path: 'new',
        component: PageTemplateFormComponent,
        canActivate: [LoginActivate],
      },
      {
        path: ':id',
        component: PageTemplateFormComponent,
        canActivate: [LoginActivate],
      },
    ],
  },
  {
    path: 'catalog/:idCatalog/fascicle/:id',
    data: {
      breadcrumb: (data: any) => {
        data: data;
      },
    },
    resolve: {
      fascicle: FascicleResolver,
    },
    component: FascicleEditComponent,
    canActivate: [LoginActivate],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'users',
    canActivate: [LoginActivate],
    data: {
      breadcrumb: 'users',
    },
    children: [
      {
        path: 'list',
        data: {
          breadcrumb: 'Listado de usuarios',
        },
        canActivate: [LoginActivate],
        component: ListComponent,
      },
    ],
  },
  {
    path: 'backups',
    canActivate: [LoginActivate],
    data: {
      breadcrumb: 'Backups',
    },
    children: [
      {
        path: 'list',
        data: {
          breadcrumb: 'Listado de backups',
        },
        canActivate: [LoginActivate],
        component: BackupsListComponent,
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
