<!--
File: product-toolbar-search.component.html
Project: catalog-cloud
File Created: Sunday, 6th March 2022 22:46:11
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:54:59
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div *ngIf="!loading" class="product-toolbar-search">
  <app-product-toolbar-search-info
    [(showInsertedProducts)]="showInsertedProducts"
    [filteredProducts]="filteredProducts.length"
    [baseProducts]="baseProducts.length"
    [allProducts]="products.length"
    [viewType]="viewType"
    [productOrder]="productOrder"
    (viewTypeChange)="changeViewType($event)"
    (orderChange)="changeOrder($event)"
    [loading]="loading"
    (forceReload)="filterProducts()"
  >
  </app-product-toolbar-search-info>
  <div
    class="search-form"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxFill
  >
    <!-- <h4>Filtros</h4>
    <small>{{ filteredProducts.length }}/{{ baseProducts.length }}</small>
    <button
      mat-icon-button
      id="btn-show-filters"
      class="btn-show-filters"
      (click)="toogleShowFilter()"
    >
      <mat-icon>expand_more</mat-icon>
    </button> -->
  </div>
  <div class="filters-form" *ngIf="showFilters">
    <div fxLayout="column" fxLayoutAlign="space-between stretch">
      <mat-form-field color="primary" appearance="fill" floatLabel="always">
        <mat-label>{{ "PRODUCT.SEARCH_TEXT" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="search_filter"
          id="search_filter"
          autocapitalize="none"
          spellcheck="false"
          #searchFilter
          autocomplete="off"
          (keyup.enter)="addFilter(searchFilter)"
        />
        <button
          mat-icon-button
          matPrefix
          [matMenuTriggerFor]="menuSearchOptions"
        >
          <mat-icon>settings</mat-icon>
        </button>
      </mat-form-field>
      <div class="chips-container">
        <mat-chip-list>
          <mat-chip color="primary" *ngFor="let item of searchTerms"
            >{{ item }}
            <mat-icon
              matChipRemove
              (click)="removeSearchItem(item)"
              color="primary"
              >cancel</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </div>
      <!-- <div fxLayout="column" fxLayoutAlign="start start" fxFill>
        <span class="product-toolbar-title product-toobar-title-grey"
          >Mostrar</span
        >
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
          {{ "PRODUCT.SHOW_INSERTED_PRODUCTS" | translate }}
          <mat-slide-toggle
            [checked]="showInsertedProducts"
            color="primary"
            (change)="toggleShowInsertedProducts()"
            style="margin-bottom: 1em !important"
            ><mat-icon>eye</mat-icon>
          </mat-slide-toggle>
        </div>
      </div> -->
    </div>
  </div>
</div>

<mat-menu #menuSearchOptions="matMenu">
  <div mat-menu-item>
    <mat-slide-toggle
      [checked]="showInsertedProducts"
      color="primary"
      (change)="toggleShowInsertedProducts()"
      style="margin-bottom: 1em !important"
      ><mat-icon>eye</mat-icon>
    </mat-slide-toggle>
    <span>{{ "PRODUCT.SHOW_INSERTED_PRODUCTS" | translate }}</span>
  </div>
  <div mat-menu-item style="min-height: 150px;">
    <b>{{ 'SEARCH.SEARCH_TYPE' | translate }} </b>
    <mat-radio-group
      class="search-type-radio-group-label"
      value="AND"
      (change)="changeSearchType($event.value)"
     >
     <mat-radio-button class="search-type-radio-button" *ngFor="let type of searchTypes" [value]="type.value"  [checked]="type.checked">
       {{type.name}}
      </mat-radio-button>

    </mat-radio-group>
  </div>
</mat-menu>
