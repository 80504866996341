<!--
File: login.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 19:04:03
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


<div fxLayout="column" fxLayoutAlign="center center" class="login-background">
  <h1><img src="./assets/img/nousmedis_logo.svg" /></h1>
  <mat-card class="login-container mat-elevation-z3">
    <mat-card-title> Inicio de sesión </mat-card-title>

    <mat-card-content *ngIf="!isLogged">
      <div fxLayoutGap="20px grid">
        <div>
          <form [formGroup]="frm">
            <div fxFill>
              <mat-form-field appearance="outline" class="full-row">
                <mat-label>{{ 'USER.USER' | translate }} </mat-label>
                <input
                  matInput
                  type="email"
                  matInput
                  #loginInput
                  formControlName="email"
                  [errorStateMatcher]="matcher"
                  placeholder="Ex. cc@nousmedis.com"
                />
                <mat-error>{{ 'USER.INVALID_MAIL' | translate }} </mat-error>
              </mat-form-field>
            </div>
            <div fxFill>
              <mat-form-field appearance="outline" class="full-row">
                <mat-label>{{ 'USER.PASSWORD' | translate }} </mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  [placeholder]="'USER.PASSWORD' | translate "
                  formControlName="password"
                />
                <mat-icon matSuffix (click)="hide = !hide">{{
                  hide ? "visibility_off" : "visibility"
                }}</mat-icon>
                <mat-error>  {{ 'USER.VOID_PASSWORD' | translate }} </mat-error>
              </mat-form-field>
              <mat-error *ngIf="badCredentials">
                  {{ 'USER.BAD_LOGIN' | translate }}
              </mat-error>
            </div>

            <div fxFill>
              <button
                mat-flat-button
                color="primary"
                (click)="tryLogin()"
                class="full-row rounded mat-elevation-z2"
                [disabled]=""
                #BtnLogin
              >
                {{ 'USER.START_SESSION' | translate }}
              </button>
            </div>
          </form>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox color="primary" #chkRemember
            ><span class="remember-login"> {{ 'USER.REMEMBER' | translate }}</span></mat-checkbox
          >
          <a [routerLink]="['/reset-password']" class="link-forget"
            >{{ 'USER.FORGOT_PASS' | translate }}</a
          >
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <span>
            <a href="#" class="register-link"
              >{{ 'USER.REGISTER' | translate }}</a
            >
          </span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-content *ngIf="isLogged">
      <div fxLayoutGap="20px grid">
        <div>
          {{'USER.LOGGED_IN' | translate}}
          <button mat-raised-button color="primary" (click)="logout()">{{'USER.CLOSE_SESSION' |translate}}</button>
        </div>
        </div>
    </mat-card-content>
  </mat-card>
</div>
