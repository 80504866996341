/*
 * File: email.validator.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 4th March 2022 10:13:12                               *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 15th March 2022 11:32:46                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  AbstractControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

export const passwordStrengthValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {

  //Strong -->  1 lower-case / 1 upper-case / 1 number / 1 special character / 8 characters
  //let pattern = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

  //Medium --> Same as strong but with 6 charactes
  //let pattern = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

  //Low --> Only 4 charactes

  let pattern = new RegExp('^.{4,}$')

  let match = pattern.test(control.value);
  if (match) return null;
  else
  {
    return {
      mail: false,
      message:"USER.PASS_STRENGHT_INVALID"

    };
  }


};
