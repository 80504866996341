<!--
 * File: page-template-form.component.html                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 1st June 2022 13:50:02                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 1st June 2022 17:58:58                            *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->



<ng-container *ngIf="loadedForm">
  {{idTemplate}}
  <form
    [formGroup]="form"
    *ngIf="form"
    autocomplete="off"
    class="base-fascicle-form"
  >
    <div formGroupName="mainFields" class="full-width">
      <app-form-generator
        *ngFor="let field of sortedFields"
        [field]="field"
        [formGroup]="fgMain"
      ></app-form-generator>
    </div>

    </form>
</ng-container>
