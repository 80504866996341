<!--
File: product-toolbar-product-details.component.html
Project: catalog-cloud
File Created: Monday, 7th March 2022 12:47:46
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:51:25
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


<div
  class="product-info-title"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  id="product-info-title"
>
  <h3 class="header-product-info-title">{{ 'PRODUCT.INFORMATION' | translate }} </h3>
  <button
    mat-icon-button
    id="showInfo"
    class="btn-show-info"
    color="primary"
    [disabled]="item == undefined"
    (click)="toogle()"

  >
    <mat-icon>expand_less</mat-icon>
  </button>
</div>


<div class="product-info-card" fxLayout="column" fxLaoutAlign="space-around stretch" *ngIf="imShow && item != undefined">


    <div class="product-info-card-header">
      <div class="product-img-container" fxFlex="15">
        <img class="product-img" src="{{ item.img }}" />
      </div>
      <div fxLayout="column" fxlayoutAlign="start center" class="product-info-card-titles" fxFlex="85">
            <span> {{ item.name }}</span>
        <div
          ><strong>Sku: </strong> {{ item.sku }}</div
        >
      </div>

      <!-- <div fxFlex="15">
        <button
        mat-mini-fab
        class="remove-img"
        color="primary"
        (click)="openProductForm(item)"
      >
        <mat-icon>edit</mat-icon>
      </button>
      </div>-->
    </div>


      <ul class="product-datails">
        <li>
          <strong>{{ "DESCRIPTION" | translate }}: </strong>
          {{ item.description }}
        </li>
        <li>
          <strong>{{ "TAGS" | translate }}: </strong>
          {{ item.tags.toString() }}
        </li>
        <li>
          <strong>{{ "PRODUCT.FAMILY" | translate }}: </strong>
          {{ item.family }}
        </li>
        <li *ngFor="let extra of item.extra">
          <strong>{{ extra.label }}: </strong>{{ extra.value }}
        </li>
        <li>
          <strong>{{ "CREATED_AT" | translate }}: </strong>
          {{ item.createdAt | date: "dd/MM/yyyy - HH:mm" }}
        </li>
      </ul>


</div>
