/*
 * File: login.component.ts                                                    *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 15th July 2022 13:24:36                              *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CookieService } from 'ngx-cookie-service';

import { ApiService } from 'src/app/catalog-cloud/services/api.service';
import { PopInfoService } from 'src/app/core/services/pop-info-service.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { IUser } from 'src/app/core/interfaces/user.interface';
import { Router } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'cc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  frm: FormGroup;

  @Input() user: IUser | undefined;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passFormControl = new FormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();
  @ViewChild('chkRemember') remember: MatCheckbox;
  // remember = new MatCheckbox(chkRem));

  msgError: string = '';
  badCredentials: boolean = false;
  hide: boolean = true;
  disable: boolean = false;
  isLogged:boolean = false;
  constructor(
    private cookie: CookieService,
    private popup: PopInfoService,
    private fb: FormBuilder,
    private userService: UserService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.isLogged = this.userService.isLogged();
    this.setForm();
    let msg = this.cookie.get('login-msg');
    if (msg.length > 0) {
      this.popup.addMessage('info', msg);
      this.cookie.delete('login-msg');
    }
  }

  setForm() {
    this.frm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  tryLogin() {
    this.disable = true;
    var data = {
      username: this.frm.get('email').value,
      password: this.frm.get('password').value,
    };

    this.userService.tryLogin(data.username, data.password).subscribe(
      (data) => {
        const userId = this.userService.decodeUserId(data['token']);
        localStorage.setItem('auth-token', data['token']);
        this.userService.setJwsTokenCookie(
          data['token'],
          data['refresh_token']
        );
        this.userService.getUserById(userId).subscribe((userData) => {
          console.log(userData);
          this.userService.setDataUser(userData, false);
          this.route.navigate(['/']);
          this.disable = false;
        });
      },
      (error) => {
        this.popup.addMessage('warn', 'Acceso incorrecto, inténtelo de nuevo.');
        this.badCredentials = true;
        this.disable = false;
      }
    );
  }

  logout(){
    this.userService.logout();
    this.isLogged = false;
  }
}
