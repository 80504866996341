/*
 * File: user.service.ts                                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Thursday, 13th October 2022 10:34:19                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { IUser } from '../../../core/interfaces/user.interface';
import { ApiService } from 'src/app/catalog-cloud/services/api.service';
import { environment } from 'src/environments/environment';

import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { ModalDialogComponent } from 'src/app/core/components/modal-dialog/modal-dialog.component';
import { CreateComponent } from '../../components/user/create/create.component';
import { UserRegister } from '../../interfaces/user/user.register';
import { PopInfoService } from 'src/app/core/services/pop-info-service.service';
import { promises } from 'dns';
import { Subject } from 'rxjs';
import { EditComponent } from '../../components/user/edit/edit.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userCookie = 'ccuser';

  url_api = {
    login: '/users/login',
    register: '/users/register',
    update: '/users/',
    exists: '/users/exists/',
    list: '/users/list',
  };

  private url_login = '/users/login';
  private url_refresh = '/api/login_refresh';

  private user: IUser;

  userChanged: Subject<boolean> = new Subject<boolean>();

  constructor(
    private cookie: CookieService,
    private api: ApiService,
    private modalDialog: MatDialog,
    private popup: PopInfoService,
    private translate: TranslateService
  ) {}

  //Private functions
  getUser(): IUser {
    return this.user;
  }

  private reset() {
    this.user = {
      id: '',
      logged: false,
      name: 'no-login',
      surname: 'no-login',
      lang: 'es_ES',
      role: 1,
      token: '-',
      refreshToken: '-',
      company: 'no-login',
    };
    this.cookie.set(
      this.userCookie,
      JSON.stringify(this.user),
      0,
      '/',
      environment.base_domain,
      true
    );

    localStorage.clear();
  }

  public decodeUserId(token: string) {
    if (token) {
      var jwt_data: any = jwt_decode(token);
      // console.log('Datos USuario', jwt_data);
      return jwt_data['id'];
    } else {
      return undefined;
    }
  }

  public setJwsTokenCookie(token: string, refresh_token: string) {
    this.user.token = token;
    this.user.refreshToken = refresh_token;
  }

  private setDataUserCookie(remember: boolean) {
    var date = new Date();
    date.setDate(date.getDate() + 10);
    if (remember)
      this.cookie.set(
        this.userCookie,
        JSON.stringify(this.user),
        1500,
        '/',
        environment.base_domain,
        true
      );
    else {
      this.cookie.set(
        this.userCookie,
        JSON.stringify(this.user),
        0,
        '/',
        environment.base_domain,
        true
      );
    }
  }

  //Public functions

  public getCompany() {
    if (this.cookie.check(this.userCookie)) {
      this.user = JSON.parse(this.cookie.get(this.userCookie));
      return this.user.company;
    } else {
      return 'Company Not Found';
    }
  }

  public isLogged(): boolean {
    if (this.cookie.check(this.userCookie)) {
      try {
        let token = JSON.parse(this.cookie.get(this.userCookie))['token'];
        let id: string = this.decodeUserId(token);
        if (id.length > 1) return true;
        else return false;
      } catch (e) {
        return false;
      }
    } else return false;

    // let user = JSON.parse(this.cookie.get('ccuser'));
    // if (!user) this.reset();
    // if (!user) return !user ? this.reset() : (user as IUser);
  }

  public setUser() {
    if (this.cookie.check(this.userCookie)) {
      this.user = JSON.parse(this.cookie.get(this.userCookie));
    } else {
      this.reset();
    }
    return this.user;
  }

  public tryLogin(user: string, pass: string) {
    let data = {
      username: user,
      password: pass,
    };
    return this.api.post(this.url_login, data);
  }

  public login(logged: any) {}

  public setDataUser(data: any, remember: boolean) {
    this.user.name = data['name'];
    this.user.surname = data['surname'];
    this.user.id = data['id'];
    this.user.role = data['role'];
    this.user.lang = data['lang'];
    this.user.logged = true;
    this.setDataUserCookie(remember);
  }

  public logout() {
    this.cookie.delete(this.userCookie);
    this.reset();
  }

  public getUsers() {
    return this.api.get(this.url_api.list);
  }

  public getUserById(userId: string) {
    return this.api.get('/users/' + userId);
  }

  public registerForm() {
    this.modalDialog
      .open(ModalDialogComponent, {
        width: '954px',
        height: 'auto',
        closeOnNavigation: false,
        disableClose: true,
        panelClass: 'mat-elevation-z8',
        data: {
          title: this.translate.instant('USER.ADD'),
          component: CreateComponent,
          type: 'form',
        },
      })
      .afterClosed()
      .subscribe((createUserForm) => {
        let data = createUserForm['mainFields'];
        this.register(data).subscribe((result) => {
          this.popup.addMessage('info', this.translate.instant('USER.ADDED'));
          this.userChanged.next(true);
        });
      });
  }

  public editForm() {
    this.modalDialog
      .open(ModalDialogComponent, {
        width: '954px',
        height: 'auto',
        closeOnNavigation: false,
        disableClose: true,
        panelClass: 'mat-elevation-z8',
        data: {
          title: this.translate.instant('USER.EDIT'),
          component: EditComponent,
          type: 'form',
          user: this.user,
        },
      })
      .afterClosed()
      .subscribe((createUserForm) => {
        let data = createUserForm['mainFields'];
        this.update(data).subscribe((result) => {
          this.popup.addMessage(
            'info',
            this.translate.instant('USER.MODIFIED')
          );
          this.userChanged.next(true);
          this.setDataUser(data, true);
          this.user = data;
          this.translate.setDefaultLang(this.user.lang);
        });
      });
  }

  public register(user: UserRegister) {
    return this.api.post(this.url_api.register, user);
  }

  public update(user: IUser) {
    return this.api.patch(this.url_api.update + user.id, user);
  }

  public exists(username: string) {
    return this.api.get(this.url_api.exists + username);
  }
}
