<div
  class="tool-container {{ side }}-cell-tool"
  *ngIf="cellButton.showButton()"
>
  <!-- <button color ="primary" mat-fab class="mat-elevation-z5 cc-boton" *ngIf="this.side == 'left'" >
    <mat-icon  *ngIf="isVisible()" (click)="expand()">add</mat-icon>
    <mat-icon *ngIf="showDecrease()" (click)="reduce()"class="removeIcon">remove</mat-icon>
  </button> -->

  <!-- <button
    color="primary"
    mat-fab
    class="mat-elevation-z5"
    *ngIf="this.side != 'left'"
  >
    <mat-icon *ngIf="showDecrease()" (click)="reduce()">remove</mat-icon>
    <mat-icon *ngIf="isVisible()" (click)="expand()">add</mat-icon>
  </button> -->

  <div *ngIf="this.side != 'left'" class="grow-buttons">
    <button
      mat-icon-button
      class="mat-elevation-z5 round-small-button minus"
      (click)="reduce()"
      *ngIf="showDecrease()"
    >
      <mat-icon>remove</mat-icon>
    </button>
    <button
      mat-icon-button
      class="mat-elevation-z5 round-small-button plus"
      (click)="expand()"
      *ngIf="isVisible()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div *ngIf="this.side == 'left'" class="grow-buttons">
    <button
      mat-icon-button
      class="mat-elevation-z5 round-small-button plus"
      (click)="expand()"
      *ngIf="isVisible()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      class="mat-elevation-z5 round-small-button minus"
      (click)="reduce()"
      *ngIf="showDecrease()"
    >
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
