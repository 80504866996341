/*
 * File: cell.service.ts                                                       *
 * Project: catalog-cloud                                                      *
 * File Created: Thursday, 17th February 2022 11:48:25                         *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Monday, 4th July 2022 15:51:22                               *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { PageCell } from '../../interfaces/fascicle/page-cell.interface';
import { Subject } from 'rxjs';
import { ProductExtra } from '../../interfaces/product/product-extra';
import { CcCellItemExtraFieldsFormComponent } from '../../components/grid/cc-cell-item-extra-fields-form/cc-cell-item-extra-fields-form.component';
import { ModalDialogComponent } from 'src/app/core/components/modal-dialog/modal-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class CellService {
  endpoint: string = '/catalog/idCatalog/fascicle/idFascicle/page/idPage/cells';

  cleanCell: Subject<string> = new Subject<string>();
  moveCell: Subject<string> = new Subject<string>();

  constructor(private api: ApiService, private dialog: MatDialog) {}

  private getBaseEndPoint(
    idCatalog: string,
    idFascicle: string,
    idPage: string
  ) {
    const mapObj = {
      idCatalog: idCatalog,
      idFascicle: idFascicle,
      idPage: idPage,
    };
    let str = this.endpoint;
    str = str.replace(
      /\b(?:idCatalog|idFascicle|idPage)\b/gi,
      (matched) => mapObj[matched]
    );
    return str;
  }

  moveProduct(
    idCatalog: string,
    idFascicle: string,
    fromIdPage: string,
    fromCell: string,
    toIdPage:string,
    toCell: string
  ) {
    const url =
      this.getBaseEndPoint(idCatalog, idFascicle, fromIdPage) + '/' + fromCell;
    return this.api.post(url, { toIdPage: toIdPage, toCell:toCell });
  }

  update(
    idCatalog: string,
    idFascicle: string,
    idPage: string,
    idCell: string,
    cell: PageCell
  ) {
    const url =
      this.getBaseEndPoint(idCatalog, idFascicle, idPage) + '/' + idCell;
    return this.api.patch(url, cell);
  }

  openExtraFieldsForm(fields: ProductExtra[]) {
    return this.dialog.open(ModalDialogComponent, {
      width: '40vw',
      height: 'auto',
      closeOnNavigation: false,
      disableClose: true,
      data: {
        title: 'Editar campos extra',
        component: CcCellItemExtraFieldsFormComponent,
        entity: fields,
        type: 'entity',
      },
    }).afterClosed();
  }
}
