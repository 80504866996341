<!--
File: catalog-home.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 16:55:10
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<!-- <mat-sidenav-container
fullscreen
fixedInViewport="true"
autosize="true"
class="main-sidenav"
>

<mat-sidenav #sidenav mode="side" opened  class="left-side-menu">
  <app-side-menu context="catalog"> </app-side-menu>
</mat-sidenav>

<div class="content-sidenav">
  <cc-top-menu></cc-top-menu>
  <app-catalog-list></app-catalog-list>
</div>

</mat-sidenav-container> -->

  <cc-top-menu context="catalog"></cc-top-menu>
  <div   style=" width: 90%; margin: 2em auto;">
    <app-catalog-list ></app-catalog-list>
  </div>


