/*
 * File: edit.component.ts                                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Tuesday, 15th March 2022 11:52:45                             *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 16th March 2022 12:33:34                          *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { userEditSchema } from 'src/app/catalog-cloud/schemas/user/user-edit.schema';
import { UserService } from 'src/app/catalog-cloud/services/user/user.service';
import { sameAsValidator } from 'src/app/core/form-validators/same-as.validator';
import { UserRepeatedValidator } from 'src/app/core/form-validators/user-repeated.validator';
import { FormCreator } from 'src/app/core/helpers/form-creator';
import { IUser } from 'src/app/core/interfaces/user.interface';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {
  @Input() user: IUser;
  formDataIsLoaded: boolean;
  form!: FormGroup;
  fbMain!: FormGroup;
  show: boolean = false;
  orderedMainFields = userEditSchema.mainFields.sort(
    (a, b) => a.order - b.order
  );
  userRepeatedValidator: UserRepeatedValidator;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private translate: TranslateService
  ) {
    this.userRepeatedValidator = new UserRepeatedValidator(
      userService,
      translate
    );
  }

  ngOnInit(): void {
    this.show = false;
    this.formDataIsLoaded = false;
    this.initForm();
    this.show = true;
    this.formDataIsLoaded = true;
  }

  initForm() {
    if (this.user) {
      for (let field in userEditSchema['mainFields']) {
        if (userEditSchema['mainFields'][field].type === 'select') {
          if (userEditSchema['mainFields'][field].data)
            userEditSchema['mainFields'][field].data.selected =
              this.user[userEditSchema['mainFields'][field].key];
        }

        userEditSchema['mainFields'][field].value =
          this.user[userEditSchema['mainFields'][field].key];
      }
    }
    const fc = new FormCreator(this.fb, userEditSchema);

    this.form = fc.getControls();
    this.fbMain = this.form.controls['mainFields'] as FormGroup;
    this.form.setErrors({ start: true });
  }
}
