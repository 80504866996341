/*
 * File: socket.service.ts                                                     *
 * Project: catalog-cloud                                                      *
 * File Created: Tuesday, 1st February 2022 21:34:49                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 25th March 2022 14:32:14                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { io, Socket, Manager } from 'socket.io-client';
import { ModalDialogComponent } from 'src/app/core/components/modal-dialog/modal-dialog.component';
import { PopInfoService } from 'src/app/core/services/pop-info-service.service';
import { environment } from 'src/environments/environment';
import { FascicleAskUnblockComponent } from '../../components/fascicle/fascicle-ask-unblock/fascicle-ask-unblock.component';
import { CatalogService } from '../catalog/catalog.service';
import { UserService } from '../user/user.service';
import { FascicleService } from './fascicle.service';

enum action {
  connect = 'connect',
  unblockQuest = 'unblock_question',
  sendMessage = 'send-message',
  getMessage = 'get-message',
  changeFascicle = 'change-fascicle'
}

enum key {
  userId = 'user-id',
  unblockRequest = 'unblock-request',
}

@Injectable({
  providedIn: 'root',
})
export class FascicleSocketService {
  private socket: Socket;
  private manager: Manager;

  fasciclesChanged:Subject<boolean> = new Subject<boolean>();
  constructor(
    private modalDialog: MatDialog,
    private userService: UserService,
    private translate: TranslateService,
    private fascicleService: FascicleService,
    private catalogService: CatalogService,
    private popup: PopInfoService,
    private route: Router
  ) {}

  startSocket(userId: string) {
    this.manager = new Manager(environment.base_socket);

    this.socket = this.manager.socket('/');
    this.listenForUser(userId);
    this.listenForUnblockRequest();
    this.listenForChangeFascicle();
  }

  private listenForChangeFascicle(){
    this.socket.on(action.changeFascicle, (data) => {
      this.fasciclesChanged.next(true);
    });
  }

  private listenForUser(userId: string) {
    this.socket.on(action.connect, () => {
      this.socket.emit(key.userId, userId);
    });

    this.socket.on(action.getMessage, (data) => {
      this.showMessage(data);
    });
  }

  private listenForUnblockRequest() {
    this.socket.on(action.unblockQuest, (data) => {
      console.log(data);
      if (data.blockedBy === this.userService.getUser().id) {
        this.openUnblockRequest(data);
      }
    });
  }

  private openUnblockRequest(data: any) {
    this.modalDialog
      .open(ModalDialogComponent, {
        width: '918px',
        height: 'auto',
        closeOnNavigation: false,
        disableClose: true,
        data: {
          title: this.translate.instant('FASCICLE.UNBLOCK_FASCICLE'),
          component: FascicleAskUnblockComponent,
          type: 'yesno',
          waiter: true,
          progressEvery: 1000,
          fascicleData: data,
          defautResponse: true,
        },
      })
      .afterClosed()
      .subscribe((request: Boolean) => {
        if (request) {
          this.fascicleService.unblock(data.catalogId, data.fascicleId);

          this.catalogService
            .getCatalog(data.catalogId)
            .subscribe((catalog) => {
              const catalogName = catalog.name;
              const fascicleName = catalog.fascicles.filter(
                (f) => f.id === data.fascicleId
              )[0].name;
              const me =
                this.userService.getUser().name +
                ' ' +
                this.userService.getUser().surname;
              this.sendMessage(
                this.translate.instant('FASCICLE.IS_UNBLOCKED', {
                  fascicleName: fascicleName,
                  catalogName: catalogName,
                  username: me,
                }),
                data.claimantId
              );
              this.sendChanges();
              this.route.navigate(['']);
            });
        }
        else{
          this.catalogService
          .getCatalog(data.catalogId)
          .subscribe((catalog) => {
            const catalogName = catalog.name;
            const fascicleName = catalog.fascicles.filter(
              (f) => f.id === data.fascicleId
            )[0].name;
            const me =
              this.userService.getUser().name +
              ' ' +
              this.userService.getUser().surname;
            this.sendMessage(
              this.translate.instant('FASCICLE.IS_NOT_UNBLOCKED', {
                fascicleName: fascicleName,
                catalogName: catalogName,
                username: me,
              }),
              data.claimantId
            );
          });
        }
      });
  }

  private showMessage(data: any) {
    if (this.userService.getUser().id == data.idUserReceptor)
      this.popup.addMessage('info', data.msg);
  }

  sendChanges()
  {
    this.socket.emit(action.changeFascicle, true);
  }

  sendMessage(msg: string, idUser: string) {
    let emidData: any = {};
    emidData.msg = msg;
    emidData.idUserReceptor = idUser;
    this.socket.emit(action.sendMessage, emidData);
  }

  sendUnblockRequestFascicle(
    catalogId: string,
    fascicleId: string,
    claimantId: string,
    blockedBy: string
  ) {
    let emidData: any = {};
    emidData.catalogId = catalogId;
    emidData.fascicleId = fascicleId;
    emidData.claimantId = claimantId;
    emidData.blockedBy = blockedBy;
    console.log(emidData);
    this.socket.emit(key.unblockRequest, emidData);
  }
}
