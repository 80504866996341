import { Injectable } from '@angular/core';
import { ApiParam, ApiParams } from '../../models/api-param.model';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoriesService {

  private baseUrl = '/catalog/:id/categories';
  constructor(private api:ApiService) { }

  getUrl(idCatalog:string ){
    var regex = /:(\w+)/g;
    return this.baseUrl.replace(regex, function(match, p1) {
        return idCatalog || ':' + p1;
    });
  }

  getByCatalog(id:string){
    return this.api.get(this.getUrl(id));
  }
}
